.section-equipment
  display: block
  position: relative
  background-image: url(../img/bg/bg_equipment.jpg)
  background-position: left center
  background-size: cover
  background-attachment: fixed
  min-height: 415px
  min-height: 21.6vw
  margin-bottom: 70px
  margin-bottom: 3.65vw
  +r(767)
    margin-bottom: 30px
  &::before
    content: ''
    display: block
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(#000000, 0.8)
  & > * 
    display: block
    position: relative
    z-index: 2

  .equipment-box 
    position: relative
    top: 100px
    top: 5.21vw
    +r(767)
      top: 30px


.equipment-box 
  display: flex
  +r(767)
    flex-direction: column
    text-align: center
  &__media
    display: block
    position: relative
    width: 44%
    +r(767)
      width: 100%
    img 
      width: 100%
      height: 100%
      object-fit: cover 
      object-position: center
  &__desc
    display: block
    position: relative
    width: 66%
    padding: 55px 70px
    padding: 2.86vw 3.65vw
    box-sizing: border-box
    background-color: #f6f6f6
    background-image: url(../img/decor/decor-equipment.png)
    background-size: cover
    &:after 
      -webkit-transform: translateX(-50%)
      -ms-transform: translateX(-50%)
      display: block
      position: absolute
      bottom: 0
      left: 50%
      width: 100%
      height: 7px
      transform: translateX(-50%)
      background-image: url(../img/decor/decor-lines-grey.png)
      content: ''
    +r(959)
      padding: 30px 30px
    +r(767)
      width: 100%
  &__title
    color: #222222
    font-size: 42px
    +fz(42, 21)
    line-height: 1.3
    font-weight: 600
    margin-bottom: 24px
  &__text
    color: #222222
    font-size: 22px
    +fz(22, 18)
    line-height: 1.2
    font-weight: 500
  &__btn
    display: block
    margin-top: 45px
    margin-top: 2.34vw
    +r(767)
      margin-top: 20px