.section-find
  display: block
  position: relative
  padding-bottom: 115px
  background-image: url(../img/bg/bg_find.png)
  background-position: center bottom
  background-repeat: no-repeat

  +r(639)
    padding-bottom: 60px

.find-box
  display: block
  position: relative
  background-color: $accent
  padding: 60px 60px 70px
  margin-top: -25px
  +r(1200)
    padding: 30px 30px 40px
  +r(639)
    padding: 20px 20px 30px
  &::after
    content: ''
    display: block
    width: 100%
    height: 9px
    position: absolute
    left: 0
    bottom: 0
    background-image: url(../img/decor/decor-lines-white.svg)
  &__title
    text-align: center
    margin-bottom: 45px
    +r(639)
      margin-bottom: 25px
    span
      display: inline-block
      position: relative
      color: #ffffff
      font-size: 40px
      +fz(40, 18)
      line-height: 1
      font-weight: 500
      &:before,
      &:after
        content: ''
        display: block
        width: 30px
        height: 8px
        background-position: center
        background-repeat: no-repeat
        background-size: cover
        position: absolute
        top: 50%
      &::before
        background-image: url(../img/decor/decor-title-left.svg)
        right: calc(100% + 10px)
      &::after
        background-image: url(../img/decor/decor-title-right.svg)
        left: calc(100% + 10px)

  &__form

    .uk-form-icon
      width: 62px
      color: #888888
      &:after
        content: ''
        display: block
        position: absolute
        top: 50%
        margin-top: -12px
        right: 0
        width: 1px
        height: 24px
        background-color: #dddddd

      > *
        width: 24px
        height: 24px


    .uk-form-icon:not(.uk-form-icon-flip)~.uk-input,
    .uk-form-icon:not(.uk-form-icon-flip)~.uk-select
      padding-left: 75px!important
      color: #222222
      font-size: 16px
      font-weight: 400
      box-sizing: border-box

    .uk-form-icon:not(.uk-form-icon-flip)~.uk-input
      +placeholder
        color: #222222
        font-size: 16px
        font-weight: 400

    .uk-radio
      width: 20px
      height: 20px
      border-color: #ffffff
      margin-right: 10px

    .uk-checkbox:checked,
    .uk-checkbox:indeterminate,
    .uk-radio:checked
      background-color: transparent

    .uk-radio:checked
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E")
      background-size: 200%

    label
      color: #ffffff
      font-size: 16px
      font-weight: 500
      cursor: pointer
      & + label
        margin-left: 30px
        margin-left: 1.56vw