.section-stats 
  display: block
  position: relative
  padding: 110px 0 100px
  background-image: url(../img/bg/bg_stats.jpg)
  background-position: left center
  background-size: cover
  background-attachment: fixed
  +r(959)
    padding: 60px 0
  +r(767)
    padding: 40px 0
  &::before
    content: ''
    display: block
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(#000000, 0.8)
  & > * 
    display: block
    position: relative
    z-index: 2

.stats-item
  display: block
  position: relative
  max-width: 270px
  margin: 0 auto
  &__header 
    display: flex
    justify-content: flex-start
    align-items: flex-end
    margin-bottom: 15px
  &__numb
    color: #edb509
    font-size: 74px
    +fz(74, 36)
    line-height: 1
    font-weight: 700
    margin-right: 25px
  &__icon
    width: 85px
    height: 85px
    color: #ffffff
    +r(959)
      width: 60px
      height: 60px
  &__title
    color: #ffffff
    font-size: 22px
    +fz(22, 18)
    font-weight: 500
  &__desc
    color: #888888
    font-size: 16px
    +fz(16, 14)
    font-weight: 400
    line-height: 26px