.section-why-choose-us
  display: block
  position: relative
  padding: 120px 0

  +r(959)
    padding: 60px 0
  +r(639)
    padding: 15px 0 30px

  .section-title
    margin-bottom: 35px
    +r(639)
      margin-bottom: 15px
    .uk-h2 
      margin-bottom: 15px

  .section-content 
    color: #555555
    font-size: 18px
    font-weight: 400
    line-height: 1.44

    ul 
      margin-top: 40px
      color: #555555
      font-size: 18px
      font-weight: 400
      line-height: 1.3
      +r(639)
        margin-top: 20px



.blocks-icon
  & > div 
    +r(639)
      margin-top: 20px!important
    &:nth-child(even)
      margin-top: 20px
      margin-bottom: 10px
      +r(639)
        margin: 0
    &:nth-child(odd)
      margin-top: 0

.block-icon
  display: block
  position: relative
  +r(639)
    max-width: 260px
    margin: 0 auto
  +r(374)
    max-width: 100%
  &__link
    display: block
    position: relative
    box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06)
    background-color: #ffffff
    +tr(0.5s)
    +hh 
      text-decoration: none
      background-color: $dark
      +tr(0.5s)
      .block-icon__ico
        border-color: #ffffff
        +tr(0.5s)
      .block-icon__title 
        color: #ffffff
        +tr(0.5s)
    &::before
      content: ''
      display: block
      width: 90%
      height: 7px
      position: absolute
      left: 50%
      transform: translateX(-50%)
      bottom: 0
      background-image: url(../img/decor/decor-lines-grey.png)
  &__box
    display: flex
    justify-content: center 
    align-items: center 
    flex-direction: column
    padding: 30px
    box-sizing: border-box
    text-align: center
  &__ico
    display: flex
    justify-content: center
    align-items: center
    width: 110px
    height: 110px
    border-radius: 55px
    border: 1px dotted #222222
    margin-bottom: 25px
    +tr(0.5s)
    > * 
      max-width: 60px
      max-height: 60px
  &__title
    color: #222222
    font-size: 22px
    line-height: 1.2
    font-weight: 600
    +tr(0.5s)
