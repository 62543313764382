+font("Barlow" , "Barlow-Thin"      , 100, normal)
+font("Barlow" , "Barlow-ExtraLight", 200, normal)
+font("Barlow" , "Barlow-Light"     , 300, normal)
+font("Barlow" , "Barlow-Regular"   , 400, normal)
+font("Barlow" , "Barlow-Italic"    , 400, italic)
+font("Barlow" , "Barlow-Medium"    , 500, normal)
+font("Barlow" , "Barlow-SemiBold"  , 600, normal)
+font("Barlow" , "Barlow-Bold"      , 700, normal)
+font("Barlow" , "Barlow-ExtraBold" , 800, normal)
+font("Barlow" , "Barlow-Black"     , 900, normal)

@font-face
  font-family: 'Font Awesome 5 Brands'
  font-style: normal
  font-weight: 400
  font-display: block
  src: url("../fonts/fa-brands-400.eot")
  src: url("../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.woff") format("woff"), url("../fonts/fa-brands-400.ttf") format("truetype"), url("../fonts/fa-brands-400.svg#fontawesome") format("svg")

@font-face
  font-family: 'Font Awesome 5 Free'
  font-style: normal
  font-weight: 400
  font-display: block
  src: url("../fonts/fa-regular-400.eot")
  src: url("../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff"), url("../fonts/fa-regular-400.ttf") format("truetype"), url("../fonts/fa-regular-400.svg#fontawesome") format("svg")

@font-face
  font-family: 'Font Awesome 5 Free'
  font-style: normal
  font-weight: 900
  font-display: block
  src: url("../fonts/fa-solid-900.eot")
  src: url("../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.woff") format("woff"), url("../fonts/fa-solid-900.ttf") format("truetype"), url("../fonts/fa-solid-900.svg#fontawesome") format("svg")