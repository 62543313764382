.section-cta
  display: block
  position: relative
  padding: 62px 0
  background-color: $accent
  background-image: url(../img/bg/bg_cta.png)
  +r(959)
    padding: 40px 0
  &__box
    display: flex
    justify-content: center
    align-items: center
    +r(959)
      flex-direction: column
  &__img
    margin-right: 25px
    margin-right: 1.3vw
    +r(1365)
      display: none
  &__title
    color: #222222
    font-size: 40px
    +fz(40, 20)
    font-weight: 700
    line-height: 1.2
  &__support
    padding-left: 60px
    padding-left: 3.13vw
    margin-left: 60px
    margin-left: 3.13vw
    margin-right: 60px
    margin-right: 3.13vw
    border-left: 1px solid #222222
    +r(1439)
      padding-left: 20px
      margin-right: 20px
      margin-left: 20px
    +r(959)
      border-left: 0
      padding: 0
      margin: 20px 0
    .support__icon
      > *
        font-size: 50px
        color: #000000
    .support__label
      font-size: 24px
      +fz(24, 18)
    .support__phone
      font-size: 36px
      +fz(36, 24)