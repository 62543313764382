// 100 Extra Light or Ultra Light;
// 200 Light or Thin;
// 300 Book or Demi;
// 400 Regular or Normal;
// 500 Medium;
// 600 Semibold or Demibold;
// 700 Bold;
// 800 Black or Extra Bold or Heavy;
// 900 Extra Black or Fat or Ultra Black

html,
body
  font-family: Barlow, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  color: #555
  font-size: 18px
  +fz(18, 14)
  font-weight: 400
  line-height: 30px

.uk-h1, .uk-h2, .uk-h3, .uk-h4, .uk-h5, .uk-h6, .uk-heading-2xlarge, .uk-heading-large, .uk-heading-medium, .uk-heading-small, .uk-heading-xlarge, h1, h2, h3, h4, h5, h6
  font-family: Barlow, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  font-weight: 700

address, dl, fieldset, figure, ol, p, pre, ul
  margin: 0 0 25px

*+address, *+dl, *+fieldset, *+figure, *+ol, *+p, *+pre, *+ul
  margin-top: 25px

.section-title
  margin-bottom: 60px
  .uk-text-meta
    color: #888888
    font-size: 18px
    +fz(18, 14)
    font-weight: 500
  .uk-h2
    display: block
    position: relative
    color: #222222
    font-size: 40px
    +fz(40, 30)
    font-weight: 700
    line-height: 1.15
    margin-top: 10px
    margin-bottom: 25px
  &::after
    content: ''
    display: inline-block
    width: 64px
    height: 8px
    background-image: url(../img/decor/decor-title-2.svg)