.checkbox-custome
  --background: #fff
  --border: #000000
  --border-hover: #bbc1e1
  --border-active: #1e2235
  --tick: #fff
  position: relative
  margin: 0

  label
    display: flex
    cursor: pointer


  input[type=checkbox]:focus,
  input[type=file]:focus,
  input[type=radio]:focus
    outline: none

  input, svg
    width: 21px
    height: 21px
    display: block
    outline: none

  input
    -webkit-appearance: none
    -moz-appearance: none
    position: relative
    outline: none
    background: var(--background)
    border: none
    margin: 0 10px 0 0
    padding: 0
    cursor: pointer
    border-radius: 4px
    -webkit-transition: box-shadow 0.3s
    transition: box-shadow 0.3s
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border))

    &:hover
      --s: 1px
      --b: var(--border-hover)

    &:checked
      --b: var(--border-active)

  svg
    pointer-events: none
    fill: none
    stroke-width: 1px
    stroke-linecap: round
    stroke-linejoin: round
    stroke: $accent
    position: absolute
    top: 0
    left: 0
    width: 21px
    height: 21px
    -webkit-transform: scale(var(--scale, 1)) translateZ(0)
    transform: scale(var(--scale, 1)) translateZ(0)

  span
    display: inline-block
    vertical-align: middle
    color: black
    font-family: Lato
    font-size: 18px
    +fz(18, 14)
    font-weight: 400
    line-height: normal

  &.path
    input:checked
      --s: 2px
      -webkit-transition-delay: 0.4s
      transition-delay: 0.4s

      + svg
        --a: 16.1 86.12
        --o: 102.22

    svg
      stroke-dasharray: var(--a, 86.12)
      stroke-dashoffset: var(--o, 86.12)
      -webkit-transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s
      transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s


/* Rating

.rating
  margin: 0 0 1em 0
  padding: 0
  border: none

  &__caption
    margin-bottom: 0.5em
    padding: 0

  &__group
    position: relative
    width: 10em
    height: 2em
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 19'%3E%3Cpath fill='%239b9b9b' d='M10 0l2.36 7.28L20 7.25l-6.19 4.47L16.19 19 10 14.48 3.83 19l2.36-7.28L0 7.25l7.66.03z'/%3E%3C/svg%3E%0A")
    background-size: 2em 1.9em
    background-repeat: repeat-x

  &__input
    position: absolute
    width: 1px
    height: 1px
    overflow: hidden
    clip: rect(0 0 0 0)

    &:focus ~ .rating__focus
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      z-index: -1
      outline: 0.2em solid #4a90e2
      outline-offset: 0.2em

  &__star
    position: absolute
    top: 0
    left: 0
    margin: 0
    height: 2em
    background-size: 2em 1.9em
    background-repeat: repeat-x

    &:hover
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 19'%3E%3Cpath fill='%237ed321' d='M10 0l2.36 7.28L20 7.25l-6.19 4.47L16.19 19 10 14.48 3.83 19l2.36-7.28L0 7.25l7.66.03z'/%3E%3C/svg%3E%0A")

  &__input:checked + .rating__star
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 19'%3E%3Cpath fill='%237ed321' d='M10 0l2.36 7.28L20 7.25l-6.19 4.47L16.19 19 10 14.48 3.83 19l2.36-7.28L0 7.25l7.66.03z'/%3E%3C/svg%3E%0A")

  &__star
    &:hover ~ .rating__star
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 19'%3E%3Cpath fill='%239b9b9b' d='M10 0l2.36 7.28L20 7.25l-6.19 4.47L16.19 19 10 14.48 3.83 19l2.36-7.28L0 7.25l7.66.03z'/%3E%3C/svg%3E%0A")

    &:nth-of-type(1)
      z-index: 5
      width: 2em

    &:nth-of-type(2)
      z-index: 4
      width: 4em

    &:nth-of-type(3)
      z-index: 3
      width: 6em

    &:nth-of-type(4)
      z-index: 2
      width: 8em

    &:nth-of-type(5)
      z-index: 1
      width: 10em

.category-item
  display: block
  position: relative
  max-width: 360px
  margin: 0 auto
  &__link
    display: block
    position: relative
    text-decoration: none
    +tr(0.5s)
    +hh
      text-decoration: none
      .category-item__title
        background-color: $accent
        color: #ffffff
        +tr(0.5s)
        &::before
          opacity: 1
  &__media
    display: block
    position: relative
    .uk-icon
      color: #ffffff
  &__title
    display: flex
    justify-content: center
    align-items: center
    position: relative
    padding: 36px 10px
    background-color: #f6f6f6
    color: #222222
    font-size: 22px
    +fz(22, 16)
    line-height: 1.3
    font-weight: 600
    text-transform: uppercase
    +tr(0.5s)
    &::before
      content: ''
      display: block
      width: 0
      height: 0
      border-left: 36px solid transparent
      border-right: 36px solid transparent
      border-bottom: 25px solid $accent
      position: absolute
      top: -25px
      left: 50%
      transform: translateX(-50%)
      opacity: 0