.page-footer
  display: block
  position: relative
  background-color: #111111

  &::before
    content: ''
    display: block
    position: absolute
    top: 0
    left: 50%
    transform: translateX(-50%)
    width: 100%
    max-width: 1450px
    height: 20px
    background-image: url(../img/decor/decor-lines.svg)

  .page-footer-top
    padding-top: 109px
    padding-bottom: 60px
    display: flex
    justify-content: space-between
    align-items: center
    flex-wrap: wrap

    +r(1220)
      flex-direction: column
      align-items: flex-start
      padding-top: 60px

    +r(639)
      padding-top: 60px
      padding-bottom: 40px

    .subscription-block
      margin-left: 20px
      margin-left: 1.04vw

      +r(1220)
        margin-left: 0
        margin-top: 20px

  .page-footer-middle
    display: block
    position: relative
    border-top: 1px solid #303030
    border-bottom: 1px solid #303030
    padding-top: 60px
    padding-bottom: 65px

    color: #888888
    font-size: 18px
    font-weight: 400
    line-height: 26px

    +r(639)
      padding-top: 40px
      padding-bottom: 40px

    .social-list
      margin-top: 45px

      .social-list__item + .social-list__item
        margin-left: 9px

      .social-list__link
        display: flex
        justify-content: center 
        align-items: center
        width: 32px
        height: 32px
        border-radius: 50%
        background-color: rgba(#ffffff, 0.1)
        +hh 
          background-color: $accent
          color: #111111

    .title
      color: #ffffff
      font-size: 22px
      font-weight: 400
      text-transform: uppercase
      margin-bottom: 30px

    .uk-nav
      li 
        &:before
          margin-bottom: -1.9em
          +tr(0.3s)
        +hh 
          &:before
            color: $accent
            +tr(0.3s)
          a 
            color: #ffffff
        a 
          padding: 0
          color: #888888
          font-size: 18px
          font-weight: 400
          font-style: normal
          letter-spacing: normal
          line-height: 40px
          +tr(0.3s)
    
    

  .page-footer-bottom
    padding: 40px 0
    text-align: center

    color: #ffffff
    font-size: 16px
    font-weight: 400
    line-height: 24px

    a 
      display: inline-block
      color: rgba(#ffffff, 0.3)
      font-size: 16px
      font-weight: 400
      line-height: 24px
      margin-left: 10px
      +tr(0.5s)
      +hh 
        color: $accent
        text-decoration: none
        +tr(0.5s)

      & + a 
        margin-left: 15px
        padding-left: 15px
        border-left: 1px solid rgba(#ffffff, 0.3)


.contacts-list 
  list-style-type: none
  padding: 0
  margin: 0
.contacts-list-item
  display: flex
  justify-content: flex-start
  align-items: center
  & + .contacts-list-item
    margin-top: 15px
    padding-top: 15px
    border-top: 1px solid #303030
  &__icon
    width: 35px
    height: 35px
    flex: 0 0 35px
    color: #ffffff
  &__desc
    color: #ffffff
    font-size: 18px
    line-height: 26px
    padding-left: 23px
  &__label
    color: #888888
    font-weight: 300
  &__content 
    font-weight: 600
    a 
      color: #ffffff
      +tr(0.3s)
      +hh 
        color: $accent
        text-decoration: none

.subscription-block
  display: flex
  justify-content: flex-end
  flex: 1
  +r(1080)
    flex-direction: column
    width: 100%
  &__title 
    border-left: 1px solid $accent
    padding-left: 20px
    padding-left: 1.04vw
    padding-right: 60px
    padding-right: 3.13vw
    h4 
      color: #ffffff
      font-size: 36px
      +fz(36, 20)
      line-height: 1
      font-weight: 700
      margin: 0
    span 
      color: rgba(#ffffff, 0.6)
      font-size: 18px
      +fz(18, 14)
      font-style: italic
      letter-spacing: normal
  &__form 
    +r(1080)
      margin-top: 20px
  &__form-box
    display: flex
    +r(639)
      flex-direction: column
      width: 100%
    .uk-input 
      width: 320px
      +r(639)
        width: 100%
    .uk-input
      background-color: transparent
      border: 1px solid #dddddd
      border-radius: 2px
      color: #ffffff
      font-size: 18px
      font-style: italic
      letter-spacing: normal

      +placeholder
        color: rgab(#ffffff, 0.3)
        font-size: 18px
        font-style: italic
        letter-spacing: normal

      &:focus
        background-color: transparent
        border-color: $accent
        color: #ffffff
    .uk-button
      margin-left: 14px
      +r(639)
        margin-left: 0
        margin-top: 14px
        width: 100%


.totop-link
  display: none
  z-index: 100
  position: fixed
  bottom: 40px
  right: 40px
  color: #888888
  font-size: 14px
  font-weight: 300
  line-height: 1.86
  text-transform: uppercase
  +tr(0.3s)
  +hh 
    color: $accent
    text-decoration: none
  +r(1200)
    display: none!important
  img, svg 
    max-width: 75px
    margin-bottom: 5px