.section-slideshow
  display: block
  position: relative
  background-color: $grey

.slideshow-item 
  &::before
    content: ''
    display: block
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(#000000, 0.6)
    z-index: 1
  &__content
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 2
  &__title
    display: block
    position: relative
    color: #ffffff
    font-size: 64px
    +fz(64, 24)
    font-weight: 600
    line-height: 70px
    margin-top: 24px
    &::after
      content: ''
      display: block
      width: 100%
      max-width: 500px
      height: 25px
      background-image: url(../img/decor/decor-lines.svg)
      margin: 45px auto 0
      +r(639)
        max-width: 60%
        height: 10px
        margin: 20px auto 0
  &__desc
    color: #ffffff
    font-size: 22px
    +fz(22, 16)
    font-weight: 400
    line-height: 32px