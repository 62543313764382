.section-category
  display: block
  position: relative
  padding-bottom: 120px

  +r(959)
    padding-bottom: 60px
  +r(767)
    padding-bottom: 40px

.category-card
  display: block
  position: relative
  height: 100%
  &__round
    width: 187px
    height: 187px
    border-radius: 50%
    background-color: #efefef
    position: absolute
    left: 50%
    transform: translateX(-50%)
    top: -32px
    z-index: 1
  &__box
    display: block
    position: relative
    padding: 0 20px 55px
    box-shadow: 0 0 56px 19px rgba(0, 0, 0, 0.06)
    background-color: #ffffff
    z-index: 2
    height: 100%
    box-sizing: border-box
    text-align: center
    +tr(0.5s)
    +hh 
      background-color: $dark
      .category-card__title,
      .category-card__intro
        color: #ffffff
      .category-card__btn
        .uk-button-default
          border-color: #ffffff
          color: #ffffff
  &__icon
    display: flex
    justify-content: center
    align-items: center
    width: 167px
    height: 167px
    border-radius: 50%
    background-color: $accent
    color: #ffffff
    margin: 0 auto 23px
    position: relative
    top: -22px
  &__title
    color: #222222
    font-size: 24px
    +fz(24, 18)
    font-weight: 700
    text-transform: uppercase
    margin-bottom: 6px
    +tr(0.5s)
  &__intro
    color: #222222
    font-size: 16px
    +fz(16, 14)
    font-weight: 400
    line-height: 1.63
    margin-bottom: 42px
    +tr(0.5s)
  &__btn 
    .uk-button-default
      +tr(0.5s)

body.page-categories-2
  .page-footer:before
    display: none


.section-equipment-2
  .equipment-box
    position: relative 
    margin-bottom: -10px
    z-index: 2