.equipment-detail
  display: block
  position: relative
  .section-title
    margin-bottom: 35px
    .uk-h2
      margin-bottom: 10px
      font-size: 30px
      +fz(30, 20)
  &__gallery
    margin-bottom: 60px
    +r(639)
      margin-bottom: 25px
  &__title
    color: #222
    font-size: 36px
    +fz(36, 22)
    font-weight: 700
    margin-bottom: 10px
  &__location
    color: #222
    font-size: 18px
    +fz(18, 14)
    font-weight: 400
    line-height: 1.44
    .uk-icon
      color: $accent
      margin-right: 10px
  &__btns
    display: flex
    justify-content: space-between
    align-items: center
    margin: 35px 0 60px

    +r(639)
      flex-direction: column
      margin: 25px 0

    a
      width: calc(50% - 5px)
      display: flex
      justify-content: center
      align-items: center
      position: relative
      line-height: 67px
      background-color: #fff9e7
      color: #222
      font-size: 18px
      font-weight: 500
      +tr(0.3s)
      +hh
        text-decoration: none
        background-color: $accent
        color: #ffffff
        svg
            color: #ffffff

      svg
        color: $accent
        margin-right: 10px
        +tr(0.3s)

      +r(639)
        width: 100%
        margin-bottom: 10px

  &__specification
    margin-top: 60px

    +r(639)
      margin-top: 40px

    table
      tr
        td:last-child
          font-weight: 600

.equipment-order
  background-color: #f6f6f6
  & + .equipment-order
    margin-top: 35px
  &__price
    background-color: #222
    padding: 50px 20px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    color: #ffffff
    color: white
    font-size: 40px
    +fz(40, 30)
    line-height: 1
    font-weight: 700
    text-align: center
    span
      display: inline-block
      position: relative
    span:before,
    span:after
      display: block
      position: absolute
      top: 15px
      width: 30px
      height: 8px
      background-position: center
      background-repeat: no-repeat
      background-size: cover
      content: ''

    span::before
      right: calc(100% + 10px)
      background-image: url(../img/decor/decor-title-left.svg)

    span::after
      left: calc(100% + 10px)
      background-image: url(../img/decor/decor-title-right.svg)

    small
      display: block
      margin-top: 10px
      color: white
      font-size: 18px
      +fz(18, 12)
      font-weight: 600
      text-transform: uppercase

  &__form
    display: flex
    align-items: center
    flex-direction: column
    position: relative
    padding: 20px 50px

    +r(1200)
      padding: 20px

    +r(1024)
      padding: 20px 10px

    +r(959)
      padding: 20px 50px

    +r(639)
      padding: 20px

    & > *
      flex: 1

    .uk-margin
      min-width: 250px

    .datepicker-inline .datepicker
      background: transparent
      border: 0

    input
      display: block
      position: relative
      width: 100%
      height: 50px
      line-height: 50px
      background-color: transparent
      border: 0
      border-bottom: 1px solid #dddddd
      color: #555
      font-size: 18px
      +fz(18, 14)
      font-weight: 500
      outline: 0

  &__value
    color: #396
    font-size: 18px
    font-weight: 500
    .uk-icon
      margin-right: 10px

.equipment-order-total
  background-color: #f6f6f6
  margin-top: 35px
  padding: 50px

  +r(1200)
    padding: 20px

  +r(1024)
    padding: 20px 10px

  +r(959)
    padding: 50px

  +r(639)
    padding: 20px

  ul
    list-style-type: none
    padding: 0
    margin: 0 0 50px
    li
      display: flex
      justify-content: space-between
      align-items: center
      color: #555
      font-size: 18px
      +fz(18, 14)
      font-weight: 500

      & + li
        margin-top: 20px

      span

        & + span
          color: #222222
          font-weight: 600

      &:last-child
        color: #222
        font-size: 22px
        +fz(22, 16)
        font-weight: 700
        padding-top: 25px
        margin-top: 35px
        border-top: 1px solid #dddddd

.js-data
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z'%3E%3C/path%3E%3Crect width='1' height='3' x='6' y='2'%3E%3C/rect%3E%3Crect width='1' height='3' x='13' y='2'%3E%3C/rect%3E%3C/svg%3E")
  background-repeat: no-repeat
  background-position: right 10px center

.datepicker--cell.-current-
  color: #ffffff
  background-color: $accent