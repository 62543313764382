.section-about 
  display: block
  position: relative
  background-color: #111111
  background-image: url(../img/decor/decor-about-left.png),  url(../img/decor/decor-about-right.png)
  background-repeat: no-repeat, no-repeat
  background-position: top 70px left -38%, bottom right -3%
  background-size: 59.3vw, 18.3vw
  padding-top: 125px
  padding-bottom: 120px
  +r(959)
    padding-top: 60px
    padding-bottom: 60px
  +r(639)
    padding-top: 40px
    padding-bottom: 40px


  color: #888888
  font-weight: 400
  line-height: 26px

  .section-title
    margin-bottom: 35px
    .uk-h2 
      color: #ffffff
      br 
        +r(767)
          display: none


.about-list 
  padding: 0
  margin: 35px 0 0 0
  list-style-type: none
  li 
    display: flex
    align-items: center
    color: #888888
    font-size: 18px
    +fz(18, 12)
    font-weight: 400
    line-height: 1.44
    & + li 
      margin-top: 25px
      +r(639)
        margin-top: 15px
    strong 
      width: 40%
      color: #ffffff
      font-size: 22px
      +fz(22, 14)
      font-weight: 500
      line-height: 30px
    span 
      width: 60%
      margin-left: 24px
      padding-left: 24px
      border-left: 1px solid #333333
      +r(639)
        padding-left: 12px
        margin-left: 12px
