.article-full,
.article-intro
  display: block
  position: relative
  &__image
    display: block
    position: relative
    img
      display: block
      width: 100%

    .video
      display: block
      position: relative
      & > a
        display: block
        position: relative
        &:before
          content: ''
          display: block
          position: absolute
          top: 50%
          left: 50%
          transform: translate(-50%, -50%)
          width: 150px
          height: 150px
          background-image: url(../img/icons/ico-video.png)

    .uk-slidenav
      display: flex
      justify-content: center 
      align-items: center
      padding: 0
      border-radius: 50%
      width: 50px
      height: 50px
      background-color: rgba(#000000, 0.7)

    .uk-slidenav-previous
      left: 20px

    .uk-slidenav-next
      right: 20px

  &__category 
    display: inline-block
    position: absolute
    left: 50px
    left: 2.6vw
    bottom: 50px
    bottom: 2.6vw
    background-color: #ffffff
    color: #222222
    font-size: 14px
    font-weight: 700
    text-transform: uppercase
    padding: 0 15px
  &__title
    color: #222222
    font-size: 30px
    +fz(30, 24)
    font-weight: 700
    line-height: 1.2
    text-align: left

  &__info 
    display: flex
    justify-content: flex-start
    align-items: center
    flex-wrap: wrap 
    border-bottom: 2px solid #eeeeee
    padding-bottom: 15px
    margin-bottom: 25px
    > * 
      color: #222222
      font-size: 16px
      +fz(16, 12)
      font-weight: 400
      margin-right: 20px
      padding-right: 20px
      margin-bottom: 10px
      border-right: 1px solid #eeeeee
      &:last-child
        margin-right: 0
        padding-right: 0
        bottom: 0
      i, svg 
        margin-right: 10px
        color: $accent

  &__date
    font-weight: 500
  &__author
    a 
      color: #555555
      font-size: 16px
      +fz(16, 12)
      font-weight: 600
      text-decoration: underline
      +tr(0.5s)
      +hh 
        color: $accent 
        text-decoration: none
  &__comments
    a 
      color: #555555
      font-size: 16px
      +fz(16, 12)
      font-weight: 400
      text-decoration: none
      +tr(0.5s)
      +hh 
        color: $accent 
        text-decoration: none
  &__share
    a 
      color: #555555
      font-size: 16px
      +fz(16, 12)
      font-weight: 400
      text-decoration: none
      +tr(0.5s)
      +hh 
        color: $accent 
        text-decoration: none

  &__content
    blockquote
      display: block
      position: relative
      margin-top: 40px
      margin-left: 50px
      padding-left: 25px
      border-left: 5px solid $accent
      color: #222222
      font-size: 24px
      +fz(24, 18)
      font-weight: 500
      line-height: 1.5
      font-style: normal
      text-align: left
      &::before
        content: ''
        display: block
        width: 27px
        height: 19px
        background-image: url(../img/icons/quote-blockquote.png)
        background-position: center 
        background-repeat: no-repeat
        position: absolute
        top: 0
        left: -55px
      .user 
        display: flex
        flex-direction: column
        margin-top: 15px
        strong
          color: #222222
          font-size: 18px
          +fz(18, 14)
          font-weight: 500
        span 
          color: #888888
          font-size: 14px
          +fz(14, 12)
          font-weight: 400

  &__bottom
    display: flex
    justify-content: space-between
    align-items: center 
    flex-wrap: wrap
    margin-top: 55px
    padding-bottom: 35px
    position: relative
    &:after 
      display: block
      position: absolute
      bottom: -13px
      left: 0
      width: 100%
      height: 13px
      background-image: url(../img/decor/decor-lines-grey.png)
      content: ''

    .article-full__share
      margin-top: 10px

  .block-form
    margin-top: 70px
    +r(1200)
      margin-top: 30px

.article-intro
  & + .article-intro
    margin-top: 60px
    +r(639)
      margin-top: 30px

.section-article-related
  margin-top: 70px
  padding-bottom: 80px
  position: relative
  +r(1200)
    margin-top: 30px
    padding-bottom: 40px
  &:after 
    display: block
    position: absolute
    bottom: -5px
    left: 0
    width: 100%
    height: 13px
    background-image: url(../img/decor/decor-lines-grey.png)
    content: ''
  .section-title 
    margin-bottom: 35px
    .uk-h2
      color: #222222
      font-size: 30px
      +fz(30, 24)
      font-weight: 700
      line-height: 1.2
      margin-bottom: 0

.section-article-reviews
  display: block
  position: relative
  margin-top: 70px
  +r(1200)
    margin-top: 30px

  .section-title 
    margin-bottom: 40px
    .uk-h2
      color: #222222
      font-size: 30px
      +fz(30, 24)
      font-weight: 700
      line-height: 1.2
      margin-bottom: 0

.sidebar
  display: block
  position: relative
  padding: 52px 40px 
  background-color: $accent
  +r(1200)
    padding: 30px 20px

.widjet
  display: block
  position: relative
  & + .widjet
    margin-top: 53px
  &__title 
    display: block
    position: relative
    color: #ffffff
    font-size: 24px
    +fz(24, 18)
    font-weight: 700
    padding-left: 40px
    margin-bottom: 23px
    &::before
      display: block
      position: absolute
      top: 50%
      left: 0
      width: 30px
      height: 8px
      margin-top: -4px
      background-position: center
      background-repeat: no-repeat
      background-size: cover
      content: ''
      background-image: url(../img/decor/decor-title-left.svg)

.widjet-search
  .uk-search-default
    display: block
    position: relative
    width: 100%
  .uk-search-input
    display: block
    width: 100%
    background-color: #ffffff
    +hh 
      background-color: #ffffff
    +placeholder()
      color: #838383
      font-size: 16px
      font-weight: 400

  .uk-search-icon
    width: 62px
    &::before
      content: ''
      display: block
      position: absolute
      top: 50%
      left: 0
      margin-top: -12px
      width: 1px
      height: 24px
      background-color: #dddddd

.widjet-category
  .widjet__title
    margin-bottom: 17px

.list-category
  list-style-type: none
  padding: 0
  margin: 0
  li
    a
      color: #ffffff
      font-size: 18px
      +fz(18, 14)
      font-weight: 400
      line-height: 36px
      &::before
        background: none
        content: '\f058'
        font-family: 'Font Awesome 5 Free'
        color: #ffffff
        display: inline-block
        font-size: 17px
        margin-right: 13px
        +tr(0.5s)
      +tr(0.5s)
      +hh 
        text-decoration: underline
        color: #ffffff
        +tr(0.5s)
        &::before
          color: #7c5e04
          +tr(0.5s)
    
.list-articles
  margin: 0
  padding: 0
  list-style-type: none
.list-articles-item
  display: block
  position: relative
  & + .list-articles-item
    margin-top: 30px
    padding-top: 30px
    border-top: 1px solid #ffffff
  &__link
    display: flex
    justify-content: flex-start
    align-items: flex-start
    +hh 
      text-decoration: none
  &__img
    width: 75px
    height: 75px
    flex: 0 0 75px 
    margin-right: 20px
  &__info
    display: block
  &__title
    color: #ffffff
    font-size: 20px
    +fz(20, 14)
    font-weight: 500
    line-height: 1.2
    margin-bottom: 10px
  &__date
    color: #ffffff
    font-size: 14px
    font-weight: 400

.widjet-gallery
  .uk-grid-collapse, .uk-grid-column-collapse
    margin-left: -5px
  .uk-grid-collapse>*, .uk-grid-column-collapse>*
    padding-left: 5px
  .uk-grid+.uk-grid-collapse, .uk-grid+.uk-grid-row-collapse, .uk-grid-collapse>.uk-grid-margin, .uk-grid-row-collapse>.uk-grid-margin
    margin-top: 5px

.widjet-tags-cloud
  .tags-list
    li 
      a 
        border-color: #ffffff