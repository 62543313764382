.range-box
  display: block
  position: relative

  .irs--round
    height: 20px
    top: -25px

  .irs--round .irs-line
    background-color: #ffffff

  .irs--round .irs-bar
    background-color: #7c5e04

  .irs--round .irs-handle
    top: 15px
    width: 45px
    height: 45px
    border: none
    background-color: transparent
    background-image: url(../img/icons/ico-range-slider.svg)
    background-size: cover
    border-radius: 0
    box-shadow: none

  &__desc
    display: flex
    justify-content: flex-start
    align-items: center 
    color: #ffffff
    font-size: 16px
    font-weight: 500
    font-style: normal
    letter-spacing: normal
    line-height: 1
    margin-top: 20px
    input 
      display: inline-block
      background: none 
      outline: none
      border: none
      width: 40px
      min-width: auto
      text-align: center
      color: #ffffff
      font-size: 16px
      font-weight: 500
      font-style: normal
      letter-spacing: normal
      line-height: 1
      pointer-events: none
      margin-top: 2px

.js-filter-content
  display: block
  +r(959)
    display: none

.rental-item
  display: flex
  justify-content: space-between
  background-color: #f6f6f6
  padding: 25px
  +tr(0.5s)
  +hh 
    box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06)
    background-color: #ffffff
    +tr(0.5s)
    .rental-item__price-box
      border: 2px solid #f6f6f6

  +r(959)
    flex-wrap: wrap
  +r(639)
    padding: 15px

  & + .rental-item
    margin-top: 45px
    +r(639)
      margin-top: 20px

  &__media
    display: block
    position: relative
    +r(959)
      width: 48%
      order: 1

    +r(639)
      width: 100%
      order: 1

    &::before
      content: ''
      display: block
      position: absolute
      top: 50%
      left: 0
      right: 0
      width: 100%
      height: 50%
      background-image: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%)
      opacity: 0.8

    img 
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center

  &__links
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    left: 0
    bottom: 20px
    width: 100%
    a 
      color: #ffffff
      font-size: 14px
      +fz(14, 12)
      font-weight: 400

      & + a 
        margin-left: 13px
        padding-left: 13px
        border-left: 1px solid #ffffff

  &__desc 
    padding: 0 30px
    flex: 1
    +r(959)
      width: 100%
      order: 3
      padding: 30px 0 0 0
    +r(639)
      width: 100%
      order: 2
      padding: 20px 0

  &__title
    color: #222222
    font-size: 24px
    font-weight: 700
    margin-bottom: 15px

  &__prices-list
    ul
      list-style-type: none
      padding: 0
      margin: 0
      display: flex
      justify-content: flex-start
      align-items: center
      flex-wrap: wrap
      li 
        display: block
        position: relative
        margin-right: 15px
        margin-bottom: 15px
        padding-left: 25px
        color: #222222
        font-family: Barlow
        font-size: 16px
        line-height: 1
        font-weight: 400
        &:last-child
          margin: 0
        &::before
          content: ''
          display: block
          width: 17px
          height: 17px
          background-image: url(../img/icons/ico-circle.svg)
          position: absolute
          top: 0
          left: 0

  &__price-delivery
    color: #222222
    font-size: 16px
    font-weight: 500
    margin: 12px 0 34px
    padding: 9px 0 10px
    border-top: 1px solid #dfdfdf
    border-bottom: 1px solid #dfdfdf

  &__specifications
    ul 
      margin: 0
      column-gap: 10px
      li 
        padding-left: 20px
        color: #222222
        font-size: 14px
        font-weight: 500
        line-height: 1.1
        &::before
          width: 5px
          left: -15px
          color: $accent

  &__price
    display: flex
    flex-direction: column
    justify-content: space-between
    position: relative
    +r(959)
      width: 48%
      order: 2
      text-align: center
    +r(639)
      width: 100%
      order: 3

  &__price-box 
    background-color: #ffffff
    text-align: center
    padding: 30px 20px
    border: 2px solid #ffffff

  &__price-title
    color: #222222
    font-size: 14px
    margin-bottom: 22px
    strong 
      display: block
      font-weight: 600
      line-height: 1.1
    span 
      display: block
      line-height: 1.1
      font-weight: 400

  &__price-current
    color: $accent
    font-size: 34px
    font-weight: 700
    line-height: 1
  &__price-old
    color: #393939
    font-size: 18px
    font-weight: 400
    line-height: 32px
    text-decoration: line-through
  &__price-btn
    margin-top: 12px
    +r(959)
      width: 100%
    .uk-button
      +r(959)
        width: 100%
