.section-blog 
  background-color: #f6f6f6
  padding-top: 120px
  padding-bottom: 163px
  background-image: url(../img/bg/bg_blog-decor.jpg)
  background-repeat: no-repeat 
  background-position: bottom right
  background-blend-mode: darken
  background-size: 784px
  background-size: 40.8vw

  +r(959)
    padding-top: 60px
    padding-bottom: 60px
  +r(767)
    padding-top: 40px
    padding-bottom: 40px
    background-size: 60vw

  .section-title
    margin-bottom: 40px
  
.blog-item
  display: block
  position: relative
  background-color: #f6f6f6
  +tr(0.5s)
  +hh 
    background-color: #ffffff
    box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06)
  &__media
    display: block
    position: relative
    a 
      display: block
      position: relative
      img 
        width: 100%
  &__category
    display: block
    position: absolute
    top: 23px
    right: 25px
    background-color: #ffffff
    color: #222222
    font-size: 14px
    +fz(14, 12)
    font-weight: 700
    text-transform: uppercase
    padding: 4px 15px
  &__body 
    padding: 30px 40px 40px
    +r(1200)
      padding: 30px 20px
  &__info 
    display: flex
    align-items: center
    margin-bottom: 30px
  &__date
    color: #222222
    font-size: 16px
    +fz(16, 13)
    font-weight: 500
  &__author
    padding-left: 15px
    margin-left: 15px
    border-left: 1px solid #dddddd
    color: #555555
    font-size: 16px
    +fz(16, 13)
    font-weight: 400
    a
      color: #555555
      font-weight: 600
      text-decoration: underline
      +tr(0.3s)
      +hh 
        color: $accent
        text-decoration: none
  &__title
    color: #222222
    font-size: 24px
    +fz(24, 18)
    font-weight: 700
    line-height: 1.33
    margin-bottom: 9px
  &__intro
    color: #555555
    font-weight: 400
    line-height: 1.67
  &__bottom 
    padding: 30px 40px
    border-top: 1px solid #eee
    +r(1200)
      padding: 20px

  &.blog-item--slider
    background-color: #ffffff
    +tr(0.5s)
    +hh 
      background-color: #ffffff
      box-shadow: none

.link-more
  display: inline-flex
  align-items: center
  color: #222222
  font-size: 18px
  font-weight: 600
  +tr(0.3s)
  +hh 
    color: $accent
    text-decoration: none
  img, svg 
    display: block
    width: 24px
    height: 15px
    flex: 0 0 24px
    margin-left: 14px
    color: $accent
    margin-top: 4px