@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-Thin.woff") format("woff"), url("../fonts/Barlow-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-ExtraLight.woff") format("woff"), url("../fonts/Barlow-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-Light.woff") format("woff"), url("../fonts/Barlow-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-Regular.woff") format("woff"), url("../fonts/Barlow-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-Italic.woff") format("woff"), url("../fonts/Barlow-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-Medium.woff") format("woff"), url("../fonts/Barlow-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-SemiBold.woff") format("woff"), url("../fonts/Barlow-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-Bold.woff") format("woff"), url("../fonts/Barlow-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-ExtraBold.woff") format("woff"), url("../fonts/Barlow-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("../fonts/Barlow-Black.woff") format("woff"), url("../fonts/Barlow-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-brands-400.eot");
  src: url("../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.woff") format("woff"), url("../fonts/fa-brands-400.ttf") format("truetype"), url("../fonts/fa-brands-400.svg#fontawesome") format("svg");
}
@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.eot");
  src: url("../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff"), url("../fonts/fa-regular-400.ttf") format("truetype"), url("../fonts/fa-regular-400.svg#fontawesome") format("svg");
}
@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.eot");
  src: url("../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.woff") format("woff"), url("../fonts/fa-solid-900.ttf") format("truetype"), url("../fonts/fa-solid-900.svg#fontawesome") format("svg");
}
html,
body {
  font-family: Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #555;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}
@media (max-width: 767px) {
  html,
  body {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  html,
  body {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

.uk-h1, .uk-h2, .uk-h3, .uk-h4, .uk-h5, .uk-h6, .uk-heading-2xlarge, .uk-heading-large, .uk-heading-medium, .uk-heading-small, .uk-heading-xlarge, h1, h2, h3, h4, h5, h6 {
  font-family: Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
}

address, dl, fieldset, figure, ol, p, pre, ul {
  margin: 0 0 25px;
}

* + address, * + dl, * + fieldset, * + figure, * + ol, * + p, * + pre, * + ul {
  margin-top: 25px;
}

.section-title {
  margin-bottom: 60px;
}
.section-title .uk-text-meta {
  color: #888888;
  font-size: 18px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .section-title .uk-text-meta {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .section-title .uk-text-meta {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}
.section-title .uk-h2 {
  display: block;
  position: relative;
  color: #222222;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.15;
  margin-top: 10px;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .section-title .uk-h2 {
    font-size: calc(30px + 17 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .section-title .uk-h2 {
    font-size: calc(30px + 10 * (100vw / 1920));
  }
}
.section-title::after {
  content: "";
  display: inline-block;
  width: 64px;
  height: 8px;
  background-image: url(../img/decor/decor-title-2.svg);
}

body {
  min-width: 320px;
}

.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.page-wrapper .page-main {
  flex-grow: 1;
}

.social {
  display: block;
  position: relative;
}

.social-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
}
.social-list__item {
  display: block;
  position: relative;
}
.social-list__item + .social-list__item {
  margin-left: 20px;
}
.social-list__link {
  display: block;
  position: relative;
  font-size: 16px;
  color: #666;
  transition: 0.5s;
}
.social-list__link:hover, .social-list__link:focus, .social-list__link:active {
  color: #ffffff;
}

.uk-container {
  max-width: 1140px;
}

.uk-container-large {
  max-width: 1270px;
}

.uk-container-xlarge {
  max-width: 1500px;
}

@media (min-width: 960px) {
  .uk-section-large {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
.uk-overlay-primary {
  background-color: rgba(0, 0, 0, 0.8);
}

.uk-nav-parent-icon > .uk-parent > a:after {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' data-svg='chevron-down'%3E%3Cpolyline fill='none' stroke='%23fff' stroke-width='1.03' points='16 7 10 13 4 7'%3E%3C/polyline%3E%3C/svg%3E");
}

.uk-nav-parent-icon > .uk-parent.uk-open > a:after {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' data-svg='chevron-up'%3E%3Cpolyline fill='none' stroke='%23fff' stroke-width='1.03' points='4 13 10 7 16 13'%3E%3C/polyline%3E%3C/svg%3E");
}

.uk-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: #edb509;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  transition: 0.5s;
}
@media (max-width: 767px) {
  .uk-button {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .uk-button {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}
.uk-button img, .uk-button svg {
  display: block;
  width: 24px;
  height: 15px;
  flex: 0 0 24px;
  margin-left: 14px;
}
.uk-button:hover, .uk-button:focus, .uk-button:active {
  color: #ffffff;
  background-color: #f6c019;
}

.uk-button-default {
  background-color: transparent;
  border: 1px solid #dddddd;
  color: #222222;
}
.uk-button-default svg {
  color: #edb509;
  transition: 0.5s;
}
.uk-button-default:hover, .uk-button-default:focus, .uk-button-default:active {
  border-color: #222222;
  background-color: #222222;
  color: #ffffff;
  transition: 0.5s;
}
.uk-button-default:hover svg, .uk-button-default:focus svg, .uk-button-default:active svg {
  color: #ffffff;
  transition: 0.5s;
}

.uk-button-secondary {
  background-color: #222222;
}

.uk-button-danger {
  border-radius: 2px;
  background-color: #f26e21;
}

.uk-button-large {
  line-height: 62px;
}

.uk-input,
.uk-select,
.uk-textarea {
  color: #222222;
  font-size: 16px;
  font-weight: 600;
}
.uk-input::placeholder,
.uk-select::placeholder,
.uk-textarea::placeholder {
  color: #222222;
  font-size: 16px;
  font-weight: 600;
}

.uk-textarea {
  height: 130px;
}

.uk-form-large:not(textarea):not([multiple]):not([size]) {
  height: 62px;
}

.uk-select:not([multiple]):not([size]) {
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A);
  background-repeat: no-repeat;
  background-position: 98% 50%;
}

.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  outline: none;
  background-color: #fff;
  color: #222222;
  border-color: #cccccc;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media only screen and (max-width: 767px) {
  .section-header {
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) {
  .section-header .uk-button {
    display: none;
  }
}

.title:after {
  content: "";
  display: block;
  position: relative;
  width: 30px;
  height: 8px;
  background-image: url(../img/decor/decor-title.svg);
  margin-top: 11px;
}

.uk-tooltip {
  max-width: 280px;
  padding: 20px;
  border-radius: 0;
  box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06);
  background: #ffffff;
  color: #222222;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.2;
  text-align: center;
}
.uk-tooltip::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 15px solid #ffffff;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.uk-dotnav {
  align-items: center;
}
.uk-dotnav > * > * {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #dddddd;
  border: 0;
}
.uk-dotnav > .uk-active > * {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #222222;
}

.uk-pagination li + li {
  margin-left: 10px;
}
.uk-pagination > * > * {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  border-radius: 2px;
  border: 1px solid #dddddd;
  padding: 0;
  color: #222222;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.5s;
}
@media (max-width: 767px) {
  .uk-pagination > * > * {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .uk-pagination > * > * {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}
@media only screen and (max-width: 639px) {
  .uk-pagination > * > * {
    width: 40px;
    height: 40px;
  }
}
.uk-pagination > .uk-active > * {
  border-color: #edb509;
  background-color: #edb509;
  color: #ffffff;
}
.uk-pagination > * > :focus,
.uk-pagination > * > :hover {
  border-color: #edb509;
  background-color: #edb509;
  color: #ffffff;
  transition: 0.5s;
}
.uk-pagination .uk-pagination-next {
  border: 0;
  transition: 0.3s;
}
.uk-pagination .uk-pagination-next img, .uk-pagination .uk-pagination-next svg {
  width: 27px;
  height: 15px;
}
.uk-pagination .uk-pagination-next:hover, .uk-pagination .uk-pagination-next:focus, .uk-pagination .uk-pagination-next:active {
  background: none;
  color: #edb509;
}

.uk-lightbox {
  background-color: rgba(0, 0, 0, 0.8);
}

.header-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 45px;
}
.header-icons::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -25px;
  left: 50%;
  margin-left: -32px;
  width: 64px;
  height: 8px;
  background-image: url(../img/decor/decor-title-2.svg);
}
.header-icons > span {
  width: 55px;
  height: 55px;
  background-size: contain !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.header-icons > span:nth-child(1) {
  background-image: url(../img/icons/ico-slideshow-1.svg);
}
.header-icons > span:nth-child(2) {
  background-image: url(../img/icons/ico-slideshow-2.svg);
}
.header-icons > span:nth-child(3) {
  background-image: url(../img/icons/ico-slideshow-3.svg);
}
.header-icons > span + span {
  margin-left: 27px;
}

.page-head {
  display: block;
  position: relative;
}
.page-head:after {
  display: block;
  width: 100%;
  max-width: 500px;
  height: 25px;
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  background-image: url(../img/decor/decor-lines.svg);
  content: "";
}
.page-head__bg {
  display: block;
  position: relative;
  background-position: center;
  background-size: cover;
  padding: 100px 0 120px;
}
@media only screen and (max-width: 639px) {
  .page-head__bg {
    padding: 80px 0;
  }
}
.page-head__bg::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}
.page-head__content {
  display: block;
  position: relative;
  z-index: 2;
}
.page-head__title {
  text-align: center;
  color: #ffffff;
  font-size: 64px;
  font-weight: 400;
  line-height: 70px;
  text-align: center;
  margin: 45px 0 35px;
}
@media (max-width: 767px) {
  .page-head__title {
    font-size: calc(32px + 54.4 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .page-head__title {
    font-size: calc(32px + 32 * (100vw / 1920));
  }
}
.page-head__breadcrumb {
  text-align: center;
}
.page-head__breadcrumb .uk-breadcrumb > * > * {
  display: inline-block;
  position: relative;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  padding: 0 15px;
}
.page-head__breadcrumb .uk-breadcrumb > * > *::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  background: #edb509;
  z-index: -1;
}
.page-head__breadcrumb .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column):before {
  display: none;
}
.page-head__breadcrumb .uk-breadcrumb > :last-child > a:not([href]), .page-head__breadcrumb .uk-breadcrumb > :last-child > span {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.page-head__breadcrumb .uk-breadcrumb > :last-child > a:not([href])::before, .page-head__breadcrumb .uk-breadcrumb > :last-child > span::before {
  display: none;
}
.page-head__form {
  display: block;
  position: relative;
  margin-top: 80px;
  padding-top: 80px;
}
@media only screen and (max-width: 959px) {
  .page-head__form {
    margin-top: 60px;
    padding-top: 60px;
  }
}
@media only screen and (max-width: 639px) {
  .page-head__form {
    margin-top: 30px;
    padding-top: 30px;
  }
}
.page-head__form:before {
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 13px;
  transform: translateX(-50%);
  background-image: url(../img/decor/decor-lines-grey.png);
  content: "";
}

.list-checked {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.list-checked li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.list-checked li + li {
  margin-top: 5px;
}
.list-checked li::before {
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  background-image: url(../img/icons/ico-check.png);
  margin-right: 14px;
}

.tags-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.tags-list li {
  margin-right: 5px;
  margin-bottom: 5px;
}
.tags-list li a {
  display: inline-block;
  color: #222222;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border: 2px solid #dddddd;
  background-color: #ffffff;
  box-sizing: border-box;
  transition: 0.3s;
}
.tags-list li a:hover, .tags-list li a:focus, .tags-list li a:active {
  background-color: #edb509;
  color: #ffffff;
  text-decoration: none;
}

.share-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
}
.share-list li {
  display: block;
  position: relative;
}
.share-list li + li {
  margin-left: 8px;
}
.share-list li a {
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  font-size: 16px;
  color: #666;
  transition: 0.5s;
}
.share-list li a:hover, .share-list li a:focus, .share-list li a:active {
  color: #ffffff;
}

.block-form {
  box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  padding: 63px 70px;
}
@media only screen and (max-width: 1200px) {
  .block-form {
    padding: 30px;
  }
}
@media only screen and (max-width: 639px) {
  .block-form {
    padding: 15px;
  }
}
.block-form .section-content p {
  color: #555555;
  font-size: 16px;
  font-style: italic;
  letter-spacing: normal;
  line-height: 26px;
  margin-bottom: 40px;
}
.block-form .section-title {
  margin-top: 0;
  margin-bottom: 15px;
}
.block-form .section-title .uk-h2 {
  color: #222222;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
}
@media only screen and (max-width: 639px) {
  .block-form .uk-button {
    width: 100%;
  }
}

.page-header {
  display: block;
  position: relative;
}

.page-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 80px;
  padding: 18px 4.17vw;
  background-color: #222222;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
@media (max-width: 767px) {
  .page-header-top {
    font-size: calc(13px + 5.1 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .page-header-top {
    font-size: calc(13px + 3 * (100vw / 1920));
  }
}
@media only screen and (max-width: 1200px) {
  .page-header-top {
    display: block;
  }
}
@media only screen and (max-width: 1440px) {
  .page-header-top {
    padding: 10px 15px;
  }
}
.page-header-top__left {
  display: block;
  position: relative;
}
@media only screen and (max-width: 1200px) {
  .page-header-top__left {
    display: none;
  }
}
.page-header-top__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (max-width: 1200px) {
  .page-header-top__right {
    justify-content: space-between;
  }
}
.page-header-top__right > * + * {
  margin-left: 2.08vw;
  padding-left: 2.08vw;
  border-left: 1px solid #555555;
}
@media only screen and (max-width: 1200px) {
  .page-header-top__right > * + * {
    margin: 0;
    padding: 0;
    border: 0;
  }
}
@media only screen and (max-width: 639px) {
  .page-header-top__right .social {
    display: none;
  }
}

.page-header-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #dddddd;
  box-sizing: border-box;
}
.page-header-bottom.uk-active {
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.15);
}
.page-header-bottom__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-header-bottom__left .logo {
  padding: 0 5.21vw 0 4.17vw;
}
@media only screen and (max-width: 1440px) {
  .page-header-bottom__left .logo {
    padding: 0 2.08vw 0 15px;
  }
}
.page-header-bottom__left .support {
  padding: 20.5px 2.08vw;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}
@media only screen and (max-width: 1440px) {
  .page-header-bottom__left .support {
    padding-top: 11px;
    padding-bottom: 12px;
  }
}
@media only screen and (max-width: 959px) {
  .page-header-bottom__left .support {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
  }
}
@media only screen and (max-width: 424px) {
  .page-header-bottom__left .support {
    display: none;
  }
}
@media only screen and (max-width: 959px) {
  .page-header-bottom__left .support__icon .svg-inline--fa,
  .page-header-bottom__left .support__icon .fas {
    margin-right: 0;
    font-size: 30px;
  }
}
@media only screen and (max-width: 959px) {
  .page-header-bottom__left .support__desc {
    display: none;
  }
}
.page-header-bottom__right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  max-width: 50%;
}
@media only screen and (max-width: 1200px) {
  .page-header-bottom__right {
    max-width: none;
  }
}
.page-header-bottom__right .uk-navbar {
  flex: 1;
  justify-content: flex-end;
}
.page-header-bottom__right .uk-navbar-nav {
  margin-right: 60px;
  margin-right: 3.13vw;
}
@media only screen and (max-width: 1440px) {
  .page-header-bottom__right .uk-navbar-nav {
    margin-right: 20px;
  }
}
.page-header-bottom__right .uk-navbar-item,
.page-header-bottom__right .uk-navbar-nav > li > a,
.page-header-bottom__right .uk-navbar-toggle {
  font-family: "Barlow";
  min-height: 100px;
}
@media only screen and (max-width: 1440px) {
  .page-header-bottom__right .uk-navbar-item,
  .page-header-bottom__right .uk-navbar-nav > li > a,
  .page-header-bottom__right .uk-navbar-toggle {
    min-height: 80px;
  }
}
@media only screen and (max-width: 959px) {
  .page-header-bottom__right .uk-navbar-item,
  .page-header-bottom__right .uk-navbar-nav > li > a,
  .page-header-bottom__right .uk-navbar-toggle {
    min-height: 60px;
  }
}
.page-header-bottom__right .uk-navbar-nav > li > a {
  color: #222222;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0 15px;
  padding: 0 0.781vw;
}
@media (max-width: 767px) {
  .page-header-bottom__right .uk-navbar-nav > li > a {
    font-size: calc(12px + 10.2 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .page-header-bottom__right .uk-navbar-nav > li > a {
    font-size: calc(12px + 6 * (100vw / 1920));
  }
}
.page-header-bottom__right .uk-navbar-nav > li > a svg, .page-header-bottom__right .uk-navbar-nav > li > a i {
  margin-left: 11px;
  color: #666666;
}
.page-header-bottom__right .uk-navbar-nav > li.uk-active a {
  color: #edb509;
}
.page-header-bottom__right [class*=uk-navbar-dropdown-bottom] {
  margin-top: 3px;
}
.page-header-bottom__right .uk-navbar-toggle {
  width: 100px;
  flex: 0 0 100px;
  border-left: 1px solid #dddddd;
}
@media only screen and (max-width: 1440px) {
  .page-header-bottom__right .uk-navbar-toggle {
    width: 80px;
    flex: 0 0 80px;
  }
}
@media only screen and (max-width: 959px) {
  .page-header-bottom__right .uk-navbar-toggle {
    width: 60px;
    flex: 0 0 60px;
  }
}
.page-header-bottom__right .uk-navbar-toggle svg {
  color: #222222;
  width: 24px;
  height: 24px;
}
.page-header-bottom__right .uk-search-navbar .uk-search-input {
  font-size: 24px;
}
@media (max-width: 767px) {
  .page-header-bottom__right .uk-search-navbar .uk-search-input {
    font-size: calc(16px + 13.6 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .page-header-bottom__right .uk-search-navbar .uk-search-input {
    font-size: calc(16px + 8 * (100vw / 1920));
  }
}
@media only screen and (max-width: 639px) {
  .page-header-bottom__right .search-btn {
    display: none;
  }
}
.page-header-bottom__right .cart-btn {
  border: 0;
}
.page-header-bottom__right .cart-btn__icon svg {
  color: #ffffff;
  width: 40px;
  height: 40px;
}

.update svg,
.update .far {
  margin-right: 10px;
  color: #edb509;
}
.update a {
  color: white;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  text-decoration: underline;
  transition: 0.3s;
}
@media (max-width: 767px) {
  .update a {
    font-size: calc(13px + 5.1 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .update a {
    font-size: calc(13px + 3 * (100vw / 1920));
  }
}
.update a:hover, .update a:focus, .update a:active {
  color: #edb509;
  text-decoration: none;
}

.time-work {
  display: flex;
  align-items: center;
  position: relative;
  color: white;
  font-size: 16px;
  font-weight: 400;
}
@media (max-width: 767px) {
  .time-work {
    font-size: calc(13px + 5.1 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .time-work {
    font-size: calc(13px + 3 * (100vw / 1920));
  }
}
.time-work svg,
.time-work .far {
  margin-right: 10px;
  color: #edb509;
}

.login-link a {
  color: white;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  transition: 0.3s;
}
@media (max-width: 767px) {
  .login-link a {
    font-size: calc(13px + 5.1 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .login-link a {
    font-size: calc(13px + 3 * (100vw / 1920));
  }
}
.login-link a:hover, .login-link a:focus, .login-link a:active {
  color: #edb509;
  text-decoration: none;
}
.login-link svg,
.login-link .far {
  margin-right: 10px;
  color: #edb509;
}

.logo {
  display: block;
  position: relative;
}
.logo__link {
  display: block;
  position: relative;
}
@media only screen and (max-width: 959px) {
  .logo__img {
    max-height: 40px;
  }
}

.support__link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  transition: 0.3s;
}
.support__link:hover, .support__link:focus, .support__link:active {
  text-decoration: none;
}
.support__link:hover .support__phone, .support__link:focus .support__phone, .support__link:active .support__phone {
  text-decoration: none;
  color: #edb509;
}
.support__icon .svg-inline--fa,
.support__icon .fas {
  font-size: 36px;
  margin-right: 20px;
  color: #edb509;
}
.support__label {
  color: #222;
  font-size: 16px;
  font-weight: 500;
}
.support__phone {
  color: #222;
  font-size: 28px;
  line-height: 1;
  font-weight: 600;
  transition: 0.3s;
}
@media (max-width: 767px) {
  .support__phone {
    font-size: calc(20px + 13.6 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .support__phone {
    font-size: calc(20px + 8 * (100vw / 1920));
  }
}

.cart-btn {
  background-color: #edb509;
  color: #ffffff;
}
.cart-btn__icon {
  display: block;
  position: relative;
}
.cart-btn__icon svg {
  color: #ffffff;
}
.cart-btn__count {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -2px;
  right: -10px;
  width: 19px;
  height: 19px;
  border-radius: 10px;
  background-color: #ffffff;
  color: #222222;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.page-footer {
  display: block;
  position: relative;
  background-color: #111111;
}
.page-footer::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1450px;
  height: 20px;
  background-image: url(../img/decor/decor-lines.svg);
}
.page-footer .page-footer-top {
  padding-top: 109px;
  padding-bottom: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1220px) {
  .page-footer .page-footer-top {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 60px;
  }
}
@media only screen and (max-width: 639px) {
  .page-footer .page-footer-top {
    padding-top: 60px;
    padding-bottom: 40px;
  }
}
.page-footer .page-footer-top .subscription-block {
  margin-left: 20px;
  margin-left: 1.04vw;
}
@media only screen and (max-width: 1220px) {
  .page-footer .page-footer-top .subscription-block {
    margin-left: 0;
    margin-top: 20px;
  }
}
.page-footer .page-footer-middle {
  display: block;
  position: relative;
  border-top: 1px solid #303030;
  border-bottom: 1px solid #303030;
  padding-top: 60px;
  padding-bottom: 65px;
  color: #888888;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}
@media only screen and (max-width: 639px) {
  .page-footer .page-footer-middle {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.page-footer .page-footer-middle .social-list {
  margin-top: 45px;
}
.page-footer .page-footer-middle .social-list .social-list__item + .social-list__item {
  margin-left: 9px;
}
.page-footer .page-footer-middle .social-list .social-list__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
}
.page-footer .page-footer-middle .social-list .social-list__link:hover, .page-footer .page-footer-middle .social-list .social-list__link:focus, .page-footer .page-footer-middle .social-list .social-list__link:active {
  background-color: #edb509;
  color: #111111;
}
.page-footer .page-footer-middle .title {
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.page-footer .page-footer-middle .uk-nav li:before {
  margin-bottom: -1.9em;
  transition: 0.3s;
}
.page-footer .page-footer-middle .uk-nav li:hover:before, .page-footer .page-footer-middle .uk-nav li:focus:before, .page-footer .page-footer-middle .uk-nav li:active:before {
  color: #edb509;
  transition: 0.3s;
}
.page-footer .page-footer-middle .uk-nav li:hover a, .page-footer .page-footer-middle .uk-nav li:focus a, .page-footer .page-footer-middle .uk-nav li:active a {
  color: #ffffff;
}
.page-footer .page-footer-middle .uk-nav li a {
  padding: 0;
  color: #888888;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 40px;
  transition: 0.3s;
}
.page-footer .page-footer-bottom {
  padding: 40px 0;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.page-footer .page-footer-bottom a {
  display: inline-block;
  color: rgba(255, 255, 255, 0.3);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 10px;
  transition: 0.5s;
}
.page-footer .page-footer-bottom a:hover, .page-footer .page-footer-bottom a:focus, .page-footer .page-footer-bottom a:active {
  color: #edb509;
  text-decoration: none;
  transition: 0.5s;
}
.page-footer .page-footer-bottom a + a {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.contacts-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.contacts-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.contacts-list-item + .contacts-list-item {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #303030;
}
.contacts-list-item__icon {
  width: 35px;
  height: 35px;
  flex: 0 0 35px;
  color: #ffffff;
}
.contacts-list-item__desc {
  color: #ffffff;
  font-size: 18px;
  line-height: 26px;
  padding-left: 23px;
}
.contacts-list-item__label {
  color: #888888;
  font-weight: 300;
}
.contacts-list-item__content {
  font-weight: 600;
}
.contacts-list-item__content a {
  color: #ffffff;
  transition: 0.3s;
}
.contacts-list-item__content a:hover, .contacts-list-item__content a:focus, .contacts-list-item__content a:active {
  color: #edb509;
  text-decoration: none;
}

.subscription-block {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}
@media only screen and (max-width: 1080px) {
  .subscription-block {
    flex-direction: column;
    width: 100%;
  }
}
.subscription-block__title {
  border-left: 1px solid #edb509;
  padding-left: 20px;
  padding-left: 1.04vw;
  padding-right: 60px;
  padding-right: 3.13vw;
}
.subscription-block__title h4 {
  color: #ffffff;
  font-size: 36px;
  line-height: 1;
  font-weight: 700;
  margin: 0;
}
@media (max-width: 767px) {
  .subscription-block__title h4 {
    font-size: calc(20px + 27.2 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .subscription-block__title h4 {
    font-size: calc(20px + 16 * (100vw / 1920));
  }
}
.subscription-block__title span {
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  font-style: italic;
  letter-spacing: normal;
}
@media (max-width: 767px) {
  .subscription-block__title span {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .subscription-block__title span {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}
@media only screen and (max-width: 1080px) {
  .subscription-block__form {
    margin-top: 20px;
  }
}
.subscription-block__form-box {
  display: flex;
}
@media only screen and (max-width: 639px) {
  .subscription-block__form-box {
    flex-direction: column;
    width: 100%;
  }
}
.subscription-block__form-box .uk-input {
  width: 320px;
}
@media only screen and (max-width: 639px) {
  .subscription-block__form-box .uk-input {
    width: 100%;
  }
}
.subscription-block__form-box .uk-input {
  background-color: transparent;
  border: 1px solid #dddddd;
  border-radius: 2px;
  color: #ffffff;
  font-size: 18px;
  font-style: italic;
  letter-spacing: normal;
}
.subscription-block__form-box .uk-input::placeholder {
  color: rgab(#ffffff, 0.3);
  font-size: 18px;
  font-style: italic;
  letter-spacing: normal;
}
.subscription-block__form-box .uk-input:focus {
  background-color: transparent;
  border-color: #edb509;
  color: #ffffff;
}
.subscription-block__form-box .uk-button {
  margin-left: 14px;
}
@media only screen and (max-width: 639px) {
  .subscription-block__form-box .uk-button {
    margin-left: 0;
    margin-top: 14px;
    width: 100%;
  }
}

.totop-link {
  display: none;
  z-index: 100;
  position: fixed;
  bottom: 40px;
  right: 40px;
  color: #888888;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.86;
  text-transform: uppercase;
  transition: 0.3s;
}
.totop-link:hover, .totop-link:focus, .totop-link:active {
  color: #edb509;
  text-decoration: none;
}
@media only screen and (max-width: 1200px) {
  .totop-link {
    display: none !important;
  }
}
.totop-link img, .totop-link svg {
  max-width: 75px;
  margin-bottom: 5px;
}

.uk-offcanvas-bar .uk-nav-parent-icon > .uk-parent > a:after {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' data-svg='chevron-down'%3E%3Cpolyline fill='none' stroke='%23fff' stroke-width='1.03' points='16 7 10 13 4 7'%3E%3C/polyline%3E%3C/svg%3E");
}
.uk-offcanvas-bar .uk-nav-parent-icon > .uk-parent.uk-open > a:after {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' data-svg='chevron-up'%3E%3Cpolyline fill='none' stroke='%23fff' stroke-width='1.03' points='4 13 10 7 16 13'%3E%3C/polyline%3E%3C/svg%3E");
}
.uk-offcanvas-bar .support__desc {
  display: block;
}
.uk-offcanvas-bar .support__label,
.uk-offcanvas-bar .support__phone {
  color: #ffffff;
}
.uk-offcanvas-bar .social-list {
  justify-content: center;
}

.checkbox-custome {
  --background: #fff;
  --border: #000000;
  --border-hover: #bbc1e1;
  --border-active: #1e2235;
  --tick: #fff;
  position: relative;
  margin: 0;
}
.checkbox-custome label {
  display: flex;
  cursor: pointer;
}
.checkbox-custome input[type=checkbox]:focus,
.checkbox-custome input[type=file]:focus,
.checkbox-custome input[type=radio]:focus {
  outline: none;
}
.checkbox-custome input, .checkbox-custome svg {
  width: 21px;
  height: 21px;
  display: block;
  outline: none;
}
.checkbox-custome input {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  outline: none;
  background: var(--background);
  border: none;
  margin: 0 10px 0 0;
  padding: 0;
  cursor: pointer;
  border-radius: 4px;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
  box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
}
.checkbox-custome input:hover {
  --s: 1px;
  --b: var(--border-hover);
}
.checkbox-custome input:checked {
  --b: var(--border-active);
}
.checkbox-custome svg {
  pointer-events: none;
  fill: none;
  stroke-width: 1px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #edb509;
  position: absolute;
  top: 0;
  left: 0;
  width: 21px;
  height: 21px;
  -webkit-transform: scale(var(--scale, 1)) translateZ(0);
  transform: scale(var(--scale, 1)) translateZ(0);
}
.checkbox-custome span {
  display: inline-block;
  vertical-align: middle;
  color: black;
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 767px) {
  .checkbox-custome span {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .checkbox-custome span {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}
.checkbox-custome.path input:checked {
  --s: 2px;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.checkbox-custome.path input:checked + svg {
  --a: 16.1 86.12;
  --o: 102.22;
}
.checkbox-custome.path svg {
  stroke-dasharray: var(--a, 86.12);
  stroke-dashoffset: var(--o, 86.12);
  -webkit-transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
  transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
}

/* Rating */
.rating {
  margin: 0 0 1em 0;
  padding: 0;
  border: none;
}
.rating__caption {
  margin-bottom: 0.5em;
  padding: 0;
}
.rating__group {
  position: relative;
  width: 10em;
  height: 2em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 19'%3E%3Cpath fill='%239b9b9b' d='M10 0l2.36 7.28L20 7.25l-6.19 4.47L16.19 19 10 14.48 3.83 19l2.36-7.28L0 7.25l7.66.03z'/%3E%3C/svg%3E%0A");
  background-size: 2em 1.9em;
  background-repeat: repeat-x;
}
.rating__input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
.rating__input:focus ~ .rating__focus {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  outline: 0.2em solid #4a90e2;
  outline-offset: 0.2em;
}
.rating__star {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  height: 2em;
  background-size: 2em 1.9em;
  background-repeat: repeat-x;
}
.rating__star:hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 19'%3E%3Cpath fill='%237ed321' d='M10 0l2.36 7.28L20 7.25l-6.19 4.47L16.19 19 10 14.48 3.83 19l2.36-7.28L0 7.25l7.66.03z'/%3E%3C/svg%3E%0A");
}
.rating__input:checked + .rating__star {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 19'%3E%3Cpath fill='%237ed321' d='M10 0l2.36 7.28L20 7.25l-6.19 4.47L16.19 19 10 14.48 3.83 19l2.36-7.28L0 7.25l7.66.03z'/%3E%3C/svg%3E%0A");
}
.rating__star:hover ~ .rating__star {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 19'%3E%3Cpath fill='%239b9b9b' d='M10 0l2.36 7.28L20 7.25l-6.19 4.47L16.19 19 10 14.48 3.83 19l2.36-7.28L0 7.25l7.66.03z'/%3E%3C/svg%3E%0A");
}
.rating__star:nth-of-type(1) {
  z-index: 5;
  width: 2em;
}
.rating__star:nth-of-type(2) {
  z-index: 4;
  width: 4em;
}
.rating__star:nth-of-type(3) {
  z-index: 3;
  width: 6em;
}
.rating__star:nth-of-type(4) {
  z-index: 2;
  width: 8em;
}
.rating__star:nth-of-type(5) {
  z-index: 1;
  width: 10em;
}

.category-item {
  display: block;
  position: relative;
  max-width: 360px;
  margin: 0 auto;
}
.category-item__link {
  display: block;
  position: relative;
  text-decoration: none;
  transition: 0.5s;
}
.category-item__link:hover, .category-item__link:focus, .category-item__link:active {
  text-decoration: none;
}
.category-item__link:hover .category-item__title, .category-item__link:focus .category-item__title, .category-item__link:active .category-item__title {
  background-color: #edb509;
  color: #ffffff;
  transition: 0.5s;
}
.category-item__link:hover .category-item__title::before, .category-item__link:focus .category-item__title::before, .category-item__link:active .category-item__title::before {
  opacity: 1;
}
.category-item__media {
  display: block;
  position: relative;
}
.category-item__media .uk-icon {
  color: #ffffff;
}
.category-item__title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 36px 10px;
  background-color: #f6f6f6;
  color: #222222;
  font-size: 22px;
  line-height: 1.3;
  font-weight: 600;
  text-transform: uppercase;
  transition: 0.5s;
}
@media (max-width: 767px) {
  .category-item__title {
    font-size: calc(16px + 10.2 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .category-item__title {
    font-size: calc(16px + 6 * (100vw / 1920));
  }
}
.category-item__title::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 36px solid transparent;
  border-right: 36px solid transparent;
  border-bottom: 25px solid #edb509;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

.section-slideshow {
  display: block;
  position: relative;
  background-color: #cccccc;
}

.slideshow-item::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}
.slideshow-item__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.slideshow-item__title {
  display: block;
  position: relative;
  color: #ffffff;
  font-size: 64px;
  font-weight: 600;
  line-height: 70px;
  margin-top: 24px;
}
@media (max-width: 767px) {
  .slideshow-item__title {
    font-size: calc(24px + 68 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .slideshow-item__title {
    font-size: calc(24px + 40 * (100vw / 1920));
  }
}
.slideshow-item__title::after {
  content: "";
  display: block;
  width: 100%;
  max-width: 500px;
  height: 25px;
  background-image: url(../img/decor/decor-lines.svg);
  margin: 45px auto 0;
}
@media only screen and (max-width: 639px) {
  .slideshow-item__title::after {
    max-width: 60%;
    height: 10px;
    margin: 20px auto 0;
  }
}
.slideshow-item__desc {
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
}
@media (max-width: 767px) {
  .slideshow-item__desc {
    font-size: calc(16px + 10.2 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .slideshow-item__desc {
    font-size: calc(16px + 6 * (100vw / 1920));
  }
}

.section-find {
  display: block;
  position: relative;
  padding-bottom: 115px;
  background-image: url(../img/bg/bg_find.png);
  background-position: center bottom;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 639px) {
  .section-find {
    padding-bottom: 60px;
  }
}

.find-box {
  display: block;
  position: relative;
  background-color: #edb509;
  padding: 60px 60px 70px;
  margin-top: -25px;
}
@media only screen and (max-width: 1200px) {
  .find-box {
    padding: 30px 30px 40px;
  }
}
@media only screen and (max-width: 639px) {
  .find-box {
    padding: 20px 20px 30px;
  }
}
.find-box::after {
  content: "";
  display: block;
  width: 100%;
  height: 9px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: url(../img/decor/decor-lines-white.svg);
}
.find-box__title {
  text-align: center;
  margin-bottom: 45px;
}
@media only screen and (max-width: 639px) {
  .find-box__title {
    margin-bottom: 25px;
  }
}
.find-box__title span {
  display: inline-block;
  position: relative;
  color: #ffffff;
  font-size: 40px;
  line-height: 1;
  font-weight: 500;
}
@media (max-width: 767px) {
  .find-box__title span {
    font-size: calc(18px + 37.4 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .find-box__title span {
    font-size: calc(18px + 22 * (100vw / 1920));
  }
}
.find-box__title span:before, .find-box__title span:after {
  content: "";
  display: block;
  width: 30px;
  height: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
}
.find-box__title span::before {
  background-image: url(../img/decor/decor-title-left.svg);
  right: calc(100% + 10px);
}
.find-box__title span::after {
  background-image: url(../img/decor/decor-title-right.svg);
  left: calc(100% + 10px);
}
.find-box__form .uk-form-icon {
  width: 62px;
  color: #888888;
}
.find-box__form .uk-form-icon:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -12px;
  right: 0;
  width: 1px;
  height: 24px;
  background-color: #dddddd;
}
.find-box__form .uk-form-icon > * {
  width: 24px;
  height: 24px;
}
.find-box__form .uk-form-icon:not(.uk-form-icon-flip) ~ .uk-input,
.find-box__form .uk-form-icon:not(.uk-form-icon-flip) ~ .uk-select {
  padding-left: 75px !important;
  color: #222222;
  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
}
.find-box__form .uk-form-icon:not(.uk-form-icon-flip) ~ .uk-input::placeholder {
  color: #222222;
  font-size: 16px;
  font-weight: 400;
}
.find-box__form .uk-radio {
  width: 20px;
  height: 20px;
  border-color: #ffffff;
  margin-right: 10px;
}
.find-box__form .uk-checkbox:checked,
.find-box__form .uk-checkbox:indeterminate,
.find-box__form .uk-radio:checked {
  background-color: transparent;
}
.find-box__form .uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-size: 200%;
}
.find-box__form label {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.find-box__form label + label {
  margin-left: 30px;
  margin-left: 1.56vw;
}

.section-category {
  display: block;
  position: relative;
  padding-bottom: 120px;
}
@media only screen and (max-width: 959px) {
  .section-category {
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .section-category {
    padding-bottom: 40px;
  }
}

.category-card {
  display: block;
  position: relative;
  height: 100%;
}
.category-card__round {
  width: 187px;
  height: 187px;
  border-radius: 50%;
  background-color: #efefef;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -32px;
  z-index: 1;
}
.category-card__box {
  display: block;
  position: relative;
  padding: 0 20px 55px;
  box-shadow: 0 0 56px 19px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  z-index: 2;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  transition: 0.5s;
}
.category-card__box:hover, .category-card__box:focus, .category-card__box:active {
  background-color: #222222;
}
.category-card__box:hover .category-card__title,
.category-card__box:hover .category-card__intro, .category-card__box:focus .category-card__title,
.category-card__box:focus .category-card__intro, .category-card__box:active .category-card__title,
.category-card__box:active .category-card__intro {
  color: #ffffff;
}
.category-card__box:hover .category-card__btn .uk-button-default, .category-card__box:focus .category-card__btn .uk-button-default, .category-card__box:active .category-card__btn .uk-button-default {
  border-color: #ffffff;
  color: #ffffff;
}
.category-card__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 167px;
  height: 167px;
  border-radius: 50%;
  background-color: #edb509;
  color: #ffffff;
  margin: 0 auto 23px;
  position: relative;
  top: -22px;
}
.category-card__title {
  color: #222222;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 6px;
  transition: 0.5s;
}
@media (max-width: 767px) {
  .category-card__title {
    font-size: calc(18px + 10.2 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .category-card__title {
    font-size: calc(18px + 6 * (100vw / 1920));
  }
}
.category-card__intro {
  color: #222222;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.63;
  margin-bottom: 42px;
  transition: 0.5s;
}
@media (max-width: 767px) {
  .category-card__intro {
    font-size: calc(14px + 3.4 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .category-card__intro {
    font-size: calc(14px + 2 * (100vw / 1920));
  }
}
.category-card__btn .uk-button-default {
  transition: 0.5s;
}

body.page-categories-2 .page-footer:before {
  display: none;
}

.section-equipment-2 .equipment-box {
  position: relative;
  margin-bottom: -10px;
  z-index: 2;
}

.section-stats {
  display: block;
  position: relative;
  padding: 110px 0 100px;
  background-image: url(../img/bg/bg_stats.jpg);
  background-position: left center;
  background-size: cover;
  background-attachment: fixed;
}
@media only screen and (max-width: 959px) {
  .section-stats {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 767px) {
  .section-stats {
    padding: 40px 0;
  }
}
.section-stats::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.section-stats > * {
  display: block;
  position: relative;
  z-index: 2;
}

.stats-item {
  display: block;
  position: relative;
  max-width: 270px;
  margin: 0 auto;
}
.stats-item__header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 15px;
}
.stats-item__numb {
  color: #edb509;
  font-size: 74px;
  line-height: 1;
  font-weight: 700;
  margin-right: 25px;
}
@media (max-width: 767px) {
  .stats-item__numb {
    font-size: calc(36px + 64.6 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .stats-item__numb {
    font-size: calc(36px + 38 * (100vw / 1920));
  }
}
.stats-item__icon {
  width: 85px;
  height: 85px;
  color: #ffffff;
}
@media only screen and (max-width: 959px) {
  .stats-item__icon {
    width: 60px;
    height: 60px;
  }
}
.stats-item__title {
  color: #ffffff;
  font-size: 22px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .stats-item__title {
    font-size: calc(18px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .stats-item__title {
    font-size: calc(18px + 4 * (100vw / 1920));
  }
}
.stats-item__desc {
  color: #888888;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
@media (max-width: 767px) {
  .stats-item__desc {
    font-size: calc(14px + 3.4 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .stats-item__desc {
    font-size: calc(14px + 2 * (100vw / 1920));
  }
}

.section-about {
  display: block;
  position: relative;
  background-color: #111111;
  background-image: url(../img/decor/decor-about-left.png), url(../img/decor/decor-about-right.png);
  background-repeat: no-repeat, no-repeat;
  background-position: top 70px left -38%, bottom right -3%;
  background-size: 59.3vw, 18.3vw;
  padding-top: 125px;
  padding-bottom: 120px;
  color: #888888;
  font-weight: 400;
  line-height: 26px;
}
@media only screen and (max-width: 959px) {
  .section-about {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 639px) {
  .section-about {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.section-about .section-title {
  margin-bottom: 35px;
}
.section-about .section-title .uk-h2 {
  color: #ffffff;
}
@media only screen and (max-width: 767px) {
  .section-about .section-title .uk-h2 br {
    display: none;
  }
}

.about-list {
  padding: 0;
  margin: 35px 0 0 0;
  list-style-type: none;
}
.about-list li {
  display: flex;
  align-items: center;
  color: #888888;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.44;
}
@media (max-width: 767px) {
  .about-list li {
    font-size: calc(12px + 10.2 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .about-list li {
    font-size: calc(12px + 6 * (100vw / 1920));
  }
}
.about-list li + li {
  margin-top: 25px;
}
@media only screen and (max-width: 639px) {
  .about-list li + li {
    margin-top: 15px;
  }
}
.about-list li strong {
  width: 40%;
  color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
}
@media (max-width: 767px) {
  .about-list li strong {
    font-size: calc(14px + 13.6 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .about-list li strong {
    font-size: calc(14px + 8 * (100vw / 1920));
  }
}
.about-list li span {
  width: 60%;
  margin-left: 24px;
  padding-left: 24px;
  border-left: 1px solid #333333;
}
@media only screen and (max-width: 639px) {
  .about-list li span {
    padding-left: 12px;
    margin-left: 12px;
  }
}

.section-steps {
  display: block;
  position: relative;
  padding-top: 110px;
  padding-bottom: 100px;
}
@media only screen and (max-width: 959px) {
  .section-steps {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 639px) {
  .section-steps {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.section-steps .section-title {
  margin-bottom: 62px;
}
.section-steps .section-title .uk-h2 {
  margin-bottom: 8px;
}

.steps-list {
  position: relative;
  outline: 0;
}
.steps-list .uk-slider-items li:first-child .steps-item::before {
  left: 105px;
  width: calc(100% - 105px);
}
.steps-list .uk-slider-items li:first-child .steps-item::after {
  content: "";
  display: block;
  position: absolute;
  top: 168px;
  left: 95px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #222222;
}
.steps-list .uk-slider-items li:last-child .steps-item::before {
  right: 85px;
  width: calc(100% - 88px);
}
.steps-list .uk-slider-items li:last-child .steps-item::after {
  content: "";
  display: block;
  position: absolute;
  top: 168px;
  right: 78px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #222222;
}

.steps-item {
  display: block;
  position: relative;
  padding-bottom: 100px;
  cursor: pointer;
}
.steps-item::before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 170px;
  background-image: url(../img/decor/decor-steps-line.png);
}
.steps-item__box {
  max-width: 155px;
  margin: 0 auto;
  text-align: center;
  position: relative;
}
.steps-item__icon {
  display: inline-block;
  position: relative;
  margin-bottom: 149px;
}
.steps-item__icon:after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 8px;
  background-color: #edb509;
  position: absolute;
  left: 50%;
  margin-left: -7px;
  bottom: -63px;
}
.steps-item__title {
  display: inline-block;
  position: relative;
}
.steps-item__title b {
  display: inline-block;
  position: absolute;
  right: calc(100% - 20px);
  bottom: -30px;
  color: #f6f6f6;
  font-size: 130px;
  font-weight: 700;
  z-index: 1;
}
@media (max-width: 767px) {
  .steps-item__title b {
    font-size: calc(110px + 34 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .steps-item__title b {
    font-size: calc(110px + 20 * (100vw / 1920));
  }
}
@media only screen and (max-width: 959px) {
  .steps-item__title b {
    right: calc(100% - 45px);
  }
}
@media only screen and (max-width: 639px) {
  .steps-item__title b {
    right: calc(100% - 25px);
  }
}
.steps-item__title span {
  display: inline-block;
  position: relative;
  color: #222222;
  font-size: 22px;
  line-height: 1.25;
  font-weight: 600;
  text-transform: uppercase;
  z-index: 2;
}
@media (max-width: 767px) {
  .steps-item__title span {
    font-size: calc(18px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .steps-item__title span {
    font-size: calc(18px + 4 * (100vw / 1920));
  }
}

.section-cta {
  display: block;
  position: relative;
  padding: 62px 0;
  background-color: #edb509;
  background-image: url(../img/bg/bg_cta.png);
}
@media only screen and (max-width: 959px) {
  .section-cta {
    padding: 40px 0;
  }
}
.section-cta__box {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 959px) {
  .section-cta__box {
    flex-direction: column;
  }
}
.section-cta__img {
  margin-right: 25px;
  margin-right: 1.3vw;
}
@media only screen and (max-width: 1365px) {
  .section-cta__img {
    display: none;
  }
}
.section-cta__title {
  color: #222222;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .section-cta__title {
    font-size: calc(20px + 34 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .section-cta__title {
    font-size: calc(20px + 20 * (100vw / 1920));
  }
}
.section-cta__support {
  padding-left: 60px;
  padding-left: 3.13vw;
  margin-left: 60px;
  margin-left: 3.13vw;
  margin-right: 60px;
  margin-right: 3.13vw;
  border-left: 1px solid #222222;
}
@media only screen and (max-width: 1439px) {
  .section-cta__support {
    padding-left: 20px;
    margin-right: 20px;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 959px) {
  .section-cta__support {
    border-left: 0;
    padding: 0;
    margin: 20px 0;
  }
}
.section-cta__support .support__icon > * {
  font-size: 50px;
  color: #000000;
}
.section-cta__support .support__label {
  font-size: 24px;
}
@media (max-width: 767px) {
  .section-cta__support .support__label {
    font-size: calc(18px + 10.2 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .section-cta__support .support__label {
    font-size: calc(18px + 6 * (100vw / 1920));
  }
}
.section-cta__support .support__phone {
  font-size: 36px;
}
@media (max-width: 767px) {
  .section-cta__support .support__phone {
    font-size: calc(24px + 20.4 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .section-cta__support .support__phone {
    font-size: calc(24px + 12 * (100vw / 1920));
  }
}

.section-why-choose-us {
  display: block;
  position: relative;
  padding: 120px 0;
}
@media only screen and (max-width: 959px) {
  .section-why-choose-us {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 639px) {
  .section-why-choose-us {
    padding: 15px 0 30px;
  }
}
.section-why-choose-us .section-title {
  margin-bottom: 35px;
}
@media only screen and (max-width: 639px) {
  .section-why-choose-us .section-title {
    margin-bottom: 15px;
  }
}
.section-why-choose-us .section-title .uk-h2 {
  margin-bottom: 15px;
}
.section-why-choose-us .section-content {
  color: #555555;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.44;
}
.section-why-choose-us .section-content ul {
  margin-top: 40px;
  color: #555555;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
}
@media only screen and (max-width: 639px) {
  .section-why-choose-us .section-content ul {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 639px) {
  .blocks-icon > div {
    margin-top: 20px !important;
  }
}
.blocks-icon > div:nth-child(even) {
  margin-top: 20px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 639px) {
  .blocks-icon > div:nth-child(even) {
    margin: 0;
  }
}
.blocks-icon > div:nth-child(odd) {
  margin-top: 0;
}

.block-icon {
  display: block;
  position: relative;
}
@media only screen and (max-width: 639px) {
  .block-icon {
    max-width: 260px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 374px) {
  .block-icon {
    max-width: 100%;
  }
}
.block-icon__link {
  display: block;
  position: relative;
  box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  transition: 0.5s;
}
.block-icon__link:hover, .block-icon__link:focus, .block-icon__link:active {
  text-decoration: none;
  background-color: #222222;
  transition: 0.5s;
}
.block-icon__link:hover .block-icon__ico, .block-icon__link:focus .block-icon__ico, .block-icon__link:active .block-icon__ico {
  border-color: #ffffff;
  transition: 0.5s;
}
.block-icon__link:hover .block-icon__title, .block-icon__link:focus .block-icon__title, .block-icon__link:active .block-icon__title {
  color: #ffffff;
  transition: 0.5s;
}
.block-icon__link::before {
  content: "";
  display: block;
  width: 90%;
  height: 7px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background-image: url(../img/decor/decor-lines-grey.png);
}
.block-icon__box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  text-align: center;
}
.block-icon__ico {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  border-radius: 55px;
  border: 1px dotted #222222;
  margin-bottom: 25px;
  transition: 0.5s;
}
.block-icon__ico > * {
  max-width: 60px;
  max-height: 60px;
}
.block-icon__title {
  color: #222222;
  font-size: 22px;
  line-height: 1.2;
  font-weight: 600;
  transition: 0.5s;
}

.section-equipment {
  display: block;
  position: relative;
  background-image: url(../img/bg/bg_equipment.jpg);
  background-position: left center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 415px;
  min-height: 21.6vw;
  margin-bottom: 70px;
  margin-bottom: 3.65vw;
}
@media only screen and (max-width: 767px) {
  .section-equipment {
    margin-bottom: 30px;
  }
}
.section-equipment::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.section-equipment > * {
  display: block;
  position: relative;
  z-index: 2;
}
.section-equipment .equipment-box {
  position: relative;
  top: 100px;
  top: 5.21vw;
}
@media only screen and (max-width: 767px) {
  .section-equipment .equipment-box {
    top: 30px;
  }
}

.equipment-box {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .equipment-box {
    flex-direction: column;
    text-align: center;
  }
}
.equipment-box__media {
  display: block;
  position: relative;
  width: 44%;
}
@media only screen and (max-width: 767px) {
  .equipment-box__media {
    width: 100%;
  }
}
.equipment-box__media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.equipment-box__desc {
  display: block;
  position: relative;
  width: 66%;
  padding: 55px 70px;
  padding: 2.86vw 3.65vw;
  box-sizing: border-box;
  background-color: #f6f6f6;
  background-image: url(../img/decor/decor-equipment.png);
  background-size: cover;
}
.equipment-box__desc:after {
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 7px;
  transform: translateX(-50%);
  background-image: url(../img/decor/decor-lines-grey.png);
  content: "";
}
@media only screen and (max-width: 959px) {
  .equipment-box__desc {
    padding: 30px 30px;
  }
}
@media only screen and (max-width: 767px) {
  .equipment-box__desc {
    width: 100%;
  }
}
.equipment-box__title {
  color: #222222;
  font-size: 42px;
  line-height: 1.3;
  font-weight: 600;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .equipment-box__title {
    font-size: calc(21px + 35.7 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .equipment-box__title {
    font-size: calc(21px + 21 * (100vw / 1920));
  }
}
.equipment-box__text {
  color: #222222;
  font-size: 22px;
  line-height: 1.2;
  font-weight: 500;
}
@media (max-width: 767px) {
  .equipment-box__text {
    font-size: calc(18px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .equipment-box__text {
    font-size: calc(18px + 4 * (100vw / 1920));
  }
}
.equipment-box__btn {
  display: block;
  margin-top: 45px;
  margin-top: 2.34vw;
}
@media only screen and (max-width: 767px) {
  .equipment-box__btn {
    margin-top: 20px;
  }
}

.section-reviews {
  display: block;
  position: relative;
  padding: 110px 0;
}
@media only screen and (max-width: 959px) {
  .section-reviews {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 767px) {
  .section-reviews {
    padding: 40px 0;
  }
}
.section-reviews .section-title {
  margin-bottom: 55px;
}

.review-item {
  display: block;
  position: relative;
  padding-top: 10px;
}
.review-item__box {
  display: block;
  position: relative;
  border-radius: 2px;
  border: 1px solid #dddddd;
  padding: 50px 70px 55px;
  box-sizing: border-box;
  background-color: #ffffff;
}
@media only screen and (max-width: 1200px) {
  .review-item__box {
    padding: 30px 40px;
  }
}
@media only screen and (max-width: 639px) {
  .review-item__box {
    padding: 30px 20px;
  }
}
.review-item__box::before {
  content: "";
  display: block;
  width: 27px;
  height: 19px;
  background-image: url(../img/icons/ico-quote.png);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: -10px;
  left: 70px;
  background-color: #ffffff;
  padding: 0 10px;
}
@media only screen and (max-width: 767px) {
  .review-item__box::before {
    left: 30px;
  }
}
.review-item__title {
  color: #222222;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.44;
  margin-bottom: 13px;
}
.review-item__text {
  color: #555555;
  font-weight: 400;
  line-height: 1.67;
}
.review-item__user {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.review-item__avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 13px;
}
.review-item__avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.review-item__info {
  margin-right: 14px;
}
.review-item__name {
  color: #222222;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 500;
}
@media (max-width: 767px) {
  .review-item__name {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .review-item__name {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}
.review-item__position {
  color: #888888;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 400;
}
@media (max-width: 767px) {
  .review-item__position {
    font-size: calc(12px + 3.4 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .review-item__position {
    font-size: calc(12px + 2 * (100vw / 1920));
  }
}
.review-item__stars {
  margin-top: -15px;
}

.section-blog {
  background-color: #f6f6f6;
  padding-top: 120px;
  padding-bottom: 163px;
  background-image: url(../img/bg/bg_blog-decor.jpg);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-blend-mode: darken;
  background-size: 784px;
  background-size: 40.8vw;
}
@media only screen and (max-width: 959px) {
  .section-blog {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .section-blog {
    padding-top: 40px;
    padding-bottom: 40px;
    background-size: 60vw;
  }
}
.section-blog .section-title {
  margin-bottom: 40px;
}

.blog-item {
  display: block;
  position: relative;
  background-color: #f6f6f6;
  transition: 0.5s;
}
.blog-item:hover, .blog-item:focus, .blog-item:active {
  background-color: #ffffff;
  box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06);
}
.blog-item__media {
  display: block;
  position: relative;
}
.blog-item__media a {
  display: block;
  position: relative;
}
.blog-item__media a img {
  width: 100%;
}
.blog-item__category {
  display: block;
  position: absolute;
  top: 23px;
  right: 25px;
  background-color: #ffffff;
  color: #222222;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 4px 15px;
}
@media (max-width: 767px) {
  .blog-item__category {
    font-size: calc(12px + 3.4 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .blog-item__category {
    font-size: calc(12px + 2 * (100vw / 1920));
  }
}
.blog-item__body {
  padding: 30px 40px 40px;
}
@media only screen and (max-width: 1200px) {
  .blog-item__body {
    padding: 30px 20px;
  }
}
.blog-item__info {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.blog-item__date {
  color: #222222;
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .blog-item__date {
    font-size: calc(13px + 5.1 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .blog-item__date {
    font-size: calc(13px + 3 * (100vw / 1920));
  }
}
.blog-item__author {
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #dddddd;
  color: #555555;
  font-size: 16px;
  font-weight: 400;
}
@media (max-width: 767px) {
  .blog-item__author {
    font-size: calc(13px + 5.1 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .blog-item__author {
    font-size: calc(13px + 3 * (100vw / 1920));
  }
}
.blog-item__author a {
  color: #555555;
  font-weight: 600;
  text-decoration: underline;
  transition: 0.3s;
}
.blog-item__author a:hover, .blog-item__author a:focus, .blog-item__author a:active {
  color: #edb509;
  text-decoration: none;
}
.blog-item__title {
  color: #222222;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.33;
  margin-bottom: 9px;
}
@media (max-width: 767px) {
  .blog-item__title {
    font-size: calc(18px + 10.2 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .blog-item__title {
    font-size: calc(18px + 6 * (100vw / 1920));
  }
}
.blog-item__intro {
  color: #555555;
  font-weight: 400;
  line-height: 1.67;
}
.blog-item__bottom {
  padding: 30px 40px;
  border-top: 1px solid #eee;
}
@media only screen and (max-width: 1200px) {
  .blog-item__bottom {
    padding: 20px;
  }
}
.blog-item.blog-item--slider {
  background-color: #ffffff;
  transition: 0.5s;
}
.blog-item.blog-item--slider:hover, .blog-item.blog-item--slider:focus, .blog-item.blog-item--slider:active {
  background-color: #ffffff;
  box-shadow: none;
}

.link-more {
  display: inline-flex;
  align-items: center;
  color: #222222;
  font-size: 18px;
  font-weight: 600;
  transition: 0.3s;
}
.link-more:hover, .link-more:focus, .link-more:active {
  color: #edb509;
  text-decoration: none;
}
.link-more img, .link-more svg {
  display: block;
  width: 24px;
  height: 15px;
  flex: 0 0 24px;
  margin-left: 14px;
  color: #edb509;
  margin-top: 4px;
}

.article-full,
.article-intro {
  display: block;
  position: relative;
}
.article-full__image,
.article-intro__image {
  display: block;
  position: relative;
}
.article-full__image img,
.article-intro__image img {
  display: block;
  width: 100%;
}
.article-full__image .video,
.article-intro__image .video {
  display: block;
  position: relative;
}
.article-full__image .video > a,
.article-intro__image .video > a {
  display: block;
  position: relative;
}
.article-full__image .video > a:before,
.article-intro__image .video > a:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background-image: url(../img/icons/ico-video.png);
}
.article-full__image .uk-slidenav,
.article-intro__image .uk-slidenav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.7);
}
.article-full__image .uk-slidenav-previous,
.article-intro__image .uk-slidenav-previous {
  left: 20px;
}
.article-full__image .uk-slidenav-next,
.article-intro__image .uk-slidenav-next {
  right: 20px;
}
.article-full__category,
.article-intro__category {
  display: inline-block;
  position: absolute;
  left: 50px;
  left: 2.6vw;
  bottom: 50px;
  bottom: 2.6vw;
  background-color: #ffffff;
  color: #222222;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 15px;
}
.article-full__title,
.article-intro__title {
  color: #222222;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  text-align: left;
}
@media (max-width: 767px) {
  .article-full__title,
  .article-intro__title {
    font-size: calc(24px + 10.2 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .article-full__title,
  .article-intro__title {
    font-size: calc(24px + 6 * (100vw / 1920));
  }
}
.article-full__info,
.article-intro__info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 2px solid #eeeeee;
  padding-bottom: 15px;
  margin-bottom: 25px;
}
.article-full__info > *,
.article-intro__info > * {
  color: #222222;
  font-size: 16px;
  font-weight: 400;
  margin-right: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
  border-right: 1px solid #eeeeee;
}
@media (max-width: 767px) {
  .article-full__info > *,
  .article-intro__info > * {
    font-size: calc(12px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .article-full__info > *,
  .article-intro__info > * {
    font-size: calc(12px + 4 * (100vw / 1920));
  }
}
.article-full__info > *:last-child,
.article-intro__info > *:last-child {
  margin-right: 0;
  padding-right: 0;
  bottom: 0;
}
.article-full__info > * i, .article-full__info > * svg,
.article-intro__info > * i,
.article-intro__info > * svg {
  margin-right: 10px;
  color: #edb509;
}
.article-full__date,
.article-intro__date {
  font-weight: 500;
}
.article-full__author a,
.article-intro__author a {
  color: #555555;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  transition: 0.5s;
}
@media (max-width: 767px) {
  .article-full__author a,
  .article-intro__author a {
    font-size: calc(12px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .article-full__author a,
  .article-intro__author a {
    font-size: calc(12px + 4 * (100vw / 1920));
  }
}
.article-full__author a:hover, .article-full__author a:focus, .article-full__author a:active,
.article-intro__author a:hover,
.article-intro__author a:focus,
.article-intro__author a:active {
  color: #edb509;
  text-decoration: none;
}
.article-full__comments a,
.article-intro__comments a {
  color: #555555;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  transition: 0.5s;
}
@media (max-width: 767px) {
  .article-full__comments a,
  .article-intro__comments a {
    font-size: calc(12px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .article-full__comments a,
  .article-intro__comments a {
    font-size: calc(12px + 4 * (100vw / 1920));
  }
}
.article-full__comments a:hover, .article-full__comments a:focus, .article-full__comments a:active,
.article-intro__comments a:hover,
.article-intro__comments a:focus,
.article-intro__comments a:active {
  color: #edb509;
  text-decoration: none;
}
.article-full__share a,
.article-intro__share a {
  color: #555555;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  transition: 0.5s;
}
@media (max-width: 767px) {
  .article-full__share a,
  .article-intro__share a {
    font-size: calc(12px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .article-full__share a,
  .article-intro__share a {
    font-size: calc(12px + 4 * (100vw / 1920));
  }
}
.article-full__share a:hover, .article-full__share a:focus, .article-full__share a:active,
.article-intro__share a:hover,
.article-intro__share a:focus,
.article-intro__share a:active {
  color: #edb509;
  text-decoration: none;
}
.article-full__content blockquote,
.article-intro__content blockquote {
  display: block;
  position: relative;
  margin-top: 40px;
  margin-left: 50px;
  padding-left: 25px;
  border-left: 5px solid #edb509;
  color: #222222;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  font-style: normal;
  text-align: left;
}
@media (max-width: 767px) {
  .article-full__content blockquote,
  .article-intro__content blockquote {
    font-size: calc(18px + 10.2 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .article-full__content blockquote,
  .article-intro__content blockquote {
    font-size: calc(18px + 6 * (100vw / 1920));
  }
}
.article-full__content blockquote::before,
.article-intro__content blockquote::before {
  content: "";
  display: block;
  width: 27px;
  height: 19px;
  background-image: url(../img/icons/quote-blockquote.png);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: -55px;
}
.article-full__content blockquote .user,
.article-intro__content blockquote .user {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
.article-full__content blockquote .user strong,
.article-intro__content blockquote .user strong {
  color: #222222;
  font-size: 18px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .article-full__content blockquote .user strong,
  .article-intro__content blockquote .user strong {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .article-full__content blockquote .user strong,
  .article-intro__content blockquote .user strong {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}
.article-full__content blockquote .user span,
.article-intro__content blockquote .user span {
  color: #888888;
  font-size: 14px;
  font-weight: 400;
}
@media (max-width: 767px) {
  .article-full__content blockquote .user span,
  .article-intro__content blockquote .user span {
    font-size: calc(12px + 3.4 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .article-full__content blockquote .user span,
  .article-intro__content blockquote .user span {
    font-size: calc(12px + 2 * (100vw / 1920));
  }
}
.article-full__bottom,
.article-intro__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 55px;
  padding-bottom: 35px;
  position: relative;
}
.article-full__bottom:after,
.article-intro__bottom:after {
  display: block;
  position: absolute;
  bottom: -13px;
  left: 0;
  width: 100%;
  height: 13px;
  background-image: url(../img/decor/decor-lines-grey.png);
  content: "";
}
.article-full__bottom .article-full__share,
.article-intro__bottom .article-full__share {
  margin-top: 10px;
}
.article-full .block-form,
.article-intro .block-form {
  margin-top: 70px;
}
@media only screen and (max-width: 1200px) {
  .article-full .block-form,
  .article-intro .block-form {
    margin-top: 30px;
  }
}

.article-intro + .article-intro {
  margin-top: 60px;
}
@media only screen and (max-width: 639px) {
  .article-intro + .article-intro {
    margin-top: 30px;
  }
}

.section-article-related {
  margin-top: 70px;
  padding-bottom: 80px;
  position: relative;
}
@media only screen and (max-width: 1200px) {
  .section-article-related {
    margin-top: 30px;
    padding-bottom: 40px;
  }
}
.section-article-related:after {
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 13px;
  background-image: url(../img/decor/decor-lines-grey.png);
  content: "";
}
.section-article-related .section-title {
  margin-bottom: 35px;
}
.section-article-related .section-title .uk-h2 {
  color: #222222;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .section-article-related .section-title .uk-h2 {
    font-size: calc(24px + 10.2 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .section-article-related .section-title .uk-h2 {
    font-size: calc(24px + 6 * (100vw / 1920));
  }
}

.section-article-reviews {
  display: block;
  position: relative;
  margin-top: 70px;
}
@media only screen and (max-width: 1200px) {
  .section-article-reviews {
    margin-top: 30px;
  }
}
.section-article-reviews .section-title {
  margin-bottom: 40px;
}
.section-article-reviews .section-title .uk-h2 {
  color: #222222;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .section-article-reviews .section-title .uk-h2 {
    font-size: calc(24px + 10.2 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .section-article-reviews .section-title .uk-h2 {
    font-size: calc(24px + 6 * (100vw / 1920));
  }
}

.sidebar {
  display: block;
  position: relative;
  padding: 52px 40px;
  background-color: #edb509;
}
@media only screen and (max-width: 1200px) {
  .sidebar {
    padding: 30px 20px;
  }
}

.widjet {
  display: block;
  position: relative;
}
.widjet + .widjet {
  margin-top: 53px;
}
.widjet__title {
  display: block;
  position: relative;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  padding-left: 40px;
  margin-bottom: 23px;
}
@media (max-width: 767px) {
  .widjet__title {
    font-size: calc(18px + 10.2 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .widjet__title {
    font-size: calc(18px + 6 * (100vw / 1920));
  }
}
.widjet__title::before {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 8px;
  margin-top: -4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  background-image: url(../img/decor/decor-title-left.svg);
}

.widjet-search .uk-search-default {
  display: block;
  position: relative;
  width: 100%;
}
.widjet-search .uk-search-input {
  display: block;
  width: 100%;
  background-color: #ffffff;
}
.widjet-search .uk-search-input:hover, .widjet-search .uk-search-input:focus, .widjet-search .uk-search-input:active {
  background-color: #ffffff;
}
.widjet-search .uk-search-input::placeholder {
  color: #838383;
  font-size: 16px;
  font-weight: 400;
}
.widjet-search .uk-search-icon {
  width: 62px;
}
.widjet-search .uk-search-icon::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  width: 1px;
  height: 24px;
  background-color: #dddddd;
}

.widjet-category .widjet__title {
  margin-bottom: 17px;
}

.list-category {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.list-category li a {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  transition: 0.5s;
}
@media (max-width: 767px) {
  .list-category li a {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .list-category li a {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}
.list-category li a::before {
  background: none;
  content: "\f058";
  font-family: "Font Awesome 5 Free";
  color: #ffffff;
  display: inline-block;
  font-size: 17px;
  margin-right: 13px;
  transition: 0.5s;
}
.list-category li a:hover, .list-category li a:focus, .list-category li a:active {
  text-decoration: underline;
  color: #ffffff;
  transition: 0.5s;
}
.list-category li a:hover::before, .list-category li a:focus::before, .list-category li a:active::before {
  color: #7c5e04;
  transition: 0.5s;
}

.list-articles {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.list-articles-item {
  display: block;
  position: relative;
}
.list-articles-item + .list-articles-item {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #ffffff;
}
.list-articles-item__link {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.list-articles-item__link:hover, .list-articles-item__link:focus, .list-articles-item__link:active {
  text-decoration: none;
}
.list-articles-item__img {
  width: 75px;
  height: 75px;
  flex: 0 0 75px;
  margin-right: 20px;
}
.list-articles-item__info {
  display: block;
}
.list-articles-item__title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .list-articles-item__title {
    font-size: calc(14px + 10.2 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .list-articles-item__title {
    font-size: calc(14px + 6 * (100vw / 1920));
  }
}
.list-articles-item__date {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

.widjet-gallery .uk-grid-collapse, .widjet-gallery .uk-grid-column-collapse {
  margin-left: -5px;
}
.widjet-gallery .uk-grid-collapse > *, .widjet-gallery .uk-grid-column-collapse > * {
  padding-left: 5px;
}
.widjet-gallery .uk-grid + .uk-grid-collapse, .widjet-gallery .uk-grid + .uk-grid-row-collapse, .widjet-gallery .uk-grid-collapse > .uk-grid-margin, .widjet-gallery .uk-grid-row-collapse > .uk-grid-margin {
  margin-top: 5px;
}

.widjet-tags-cloud .tags-list li a {
  border-color: #ffffff;
}

.contacts-block {
  display: block;
  position: relative;
}
.contacts-block .sidebar {
  height: 100%;
  box-sizing: border-box;
  padding: 40px;
}
@media only screen and (max-width: 639px) {
  .contacts-block .sidebar {
    padding: 20px;
  }
}
.contacts-block .block-form {
  height: 100%;
  box-sizing: border-box;
}
@media only screen and (max-width: 959px) {
  .contacts-block .block-form {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 639px) {
  .contacts-block .block-form {
    margin-top: 15px;
  }
}
.contacts-block .contacts-list .contacts-list-item {
  align-items: flex-start;
}
.contacts-block .contacts-list .contacts-list-item + .contacts-list-item {
  margin-top: 40px;
  padding-top: 40px;
  border-color: #ffffff;
}
@media only screen and (max-width: 639px) {
  .contacts-block .contacts-list .contacts-list-item + .contacts-list-item {
    margin-top: 20px;
    padding-top: 20px;
  }
}
.contacts-block .contacts-list .contacts-list-item__icon {
  color: #ffffff;
}
.contacts-block .contacts-list .contacts-list-item__icon .cls-1 {
  stroke: #ffffff;
}
.contacts-block .contacts-list .contacts-list-item__desc {
  padding-left: 20px;
}
.contacts-block .contacts-list .contacts-list-item__label {
  color: #ffffff;
}
.contacts-block .contacts-list .contacts-list-item__content a:hover, .contacts-block .contacts-list .contacts-list-item__content a:focus, .contacts-block .contacts-list .contacts-list-item__content a:active {
  color: #222222;
}

.contacts-map {
  display: block;
  position: relative;
  margin-top: 70px;
}
@media only screen and (max-width: 959px) {
  .contacts-map {
    margin-top: 60px;
  }
}
@media only screen and (max-width: 639px) {
  .contacts-map {
    margin-top: 30px;
  }
}
.contacts-map iframe {
  width: 100%;
  height: 500px;
}
@media only screen and (max-width: 639px) {
  .contacts-map iframe {
    height: 300px;
  }
}

.range-box {
  display: block;
  position: relative;
}
.range-box .irs--round {
  height: 20px;
  top: -25px;
}
.range-box .irs--round .irs-line {
  background-color: #ffffff;
}
.range-box .irs--round .irs-bar {
  background-color: #7c5e04;
}
.range-box .irs--round .irs-handle {
  top: 15px;
  width: 45px;
  height: 45px;
  border: none;
  background-color: transparent;
  background-image: url(../img/icons/ico-range-slider.svg);
  background-size: cover;
  border-radius: 0;
  box-shadow: none;
}
.range-box__desc {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
  margin-top: 20px;
}
.range-box__desc input {
  display: inline-block;
  background: none;
  outline: none;
  border: none;
  width: 40px;
  min-width: auto;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
  pointer-events: none;
  margin-top: 2px;
}

.js-filter-content {
  display: block;
}
@media only screen and (max-width: 959px) {
  .js-filter-content {
    display: none;
  }
}

.rental-item {
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f6;
  padding: 25px;
  transition: 0.5s;
}
.rental-item:hover, .rental-item:focus, .rental-item:active {
  box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  transition: 0.5s;
}
.rental-item:hover .rental-item__price-box, .rental-item:focus .rental-item__price-box, .rental-item:active .rental-item__price-box {
  border: 2px solid #f6f6f6;
}
@media only screen and (max-width: 959px) {
  .rental-item {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 639px) {
  .rental-item {
    padding: 15px;
  }
}
.rental-item + .rental-item {
  margin-top: 45px;
}
@media only screen and (max-width: 639px) {
  .rental-item + .rental-item {
    margin-top: 20px;
  }
}
.rental-item__media {
  display: block;
  position: relative;
}
@media only screen and (max-width: 959px) {
  .rental-item__media {
    width: 48%;
    order: 1;
  }
}
@media only screen and (max-width: 639px) {
  .rental-item__media {
    width: 100%;
    order: 1;
  }
}
.rental-item__media::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  height: 50%;
  background-image: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.8;
}
.rental-item__media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.rental-item__links {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
}
.rental-item__links a {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
@media (max-width: 767px) {
  .rental-item__links a {
    font-size: calc(12px + 3.4 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .rental-item__links a {
    font-size: calc(12px + 2 * (100vw / 1920));
  }
}
.rental-item__links a + a {
  margin-left: 13px;
  padding-left: 13px;
  border-left: 1px solid #ffffff;
}
.rental-item__desc {
  padding: 0 30px;
  flex: 1;
}
@media only screen and (max-width: 959px) {
  .rental-item__desc {
    width: 100%;
    order: 3;
    padding: 30px 0 0 0;
  }
}
@media only screen and (max-width: 639px) {
  .rental-item__desc {
    width: 100%;
    order: 2;
    padding: 20px 0;
  }
}
.rental-item__title {
  color: #222222;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}
.rental-item__prices-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.rental-item__prices-list ul li {
  display: block;
  position: relative;
  margin-right: 15px;
  margin-bottom: 15px;
  padding-left: 25px;
  color: #222222;
  font-family: Barlow;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
}
.rental-item__prices-list ul li:last-child {
  margin: 0;
}
.rental-item__prices-list ul li::before {
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  background-image: url(../img/icons/ico-circle.svg);
  position: absolute;
  top: 0;
  left: 0;
}
.rental-item__price-delivery {
  color: #222222;
  font-size: 16px;
  font-weight: 500;
  margin: 12px 0 34px;
  padding: 9px 0 10px;
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
}
.rental-item__specifications ul {
  margin: 0;
  column-gap: 10px;
}
.rental-item__specifications ul li {
  padding-left: 20px;
  color: #222222;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
}
.rental-item__specifications ul li::before {
  width: 5px;
  left: -15px;
  color: #edb509;
}
.rental-item__price {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
@media only screen and (max-width: 959px) {
  .rental-item__price {
    width: 48%;
    order: 2;
    text-align: center;
  }
}
@media only screen and (max-width: 639px) {
  .rental-item__price {
    width: 100%;
    order: 3;
  }
}
.rental-item__price-box {
  background-color: #ffffff;
  text-align: center;
  padding: 30px 20px;
  border: 2px solid #ffffff;
}
.rental-item__price-title {
  color: #222222;
  font-size: 14px;
  margin-bottom: 22px;
}
.rental-item__price-title strong {
  display: block;
  font-weight: 600;
  line-height: 1.1;
}
.rental-item__price-title span {
  display: block;
  line-height: 1.1;
  font-weight: 400;
}
.rental-item__price-current {
  color: #edb509;
  font-size: 34px;
  font-weight: 700;
  line-height: 1;
}
.rental-item__price-old {
  color: #393939;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  text-decoration: line-through;
}
.rental-item__price-btn {
  margin-top: 12px;
}
@media only screen and (max-width: 959px) {
  .rental-item__price-btn {
    width: 100%;
  }
}
@media only screen and (max-width: 959px) {
  .rental-item__price-btn .uk-button {
    width: 100%;
  }
}

.equipment-detail {
  display: block;
  position: relative;
}
.equipment-detail .section-title {
  margin-bottom: 35px;
}
.equipment-detail .section-title .uk-h2 {
  margin-bottom: 10px;
  font-size: 30px;
}
@media (max-width: 767px) {
  .equipment-detail .section-title .uk-h2 {
    font-size: calc(20px + 17 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .equipment-detail .section-title .uk-h2 {
    font-size: calc(20px + 10 * (100vw / 1920));
  }
}
.equipment-detail__gallery {
  margin-bottom: 60px;
}
@media only screen and (max-width: 639px) {
  .equipment-detail__gallery {
    margin-bottom: 25px;
  }
}
.equipment-detail__title {
  color: #222;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .equipment-detail__title {
    font-size: calc(22px + 23.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .equipment-detail__title {
    font-size: calc(22px + 14 * (100vw / 1920));
  }
}
.equipment-detail__location {
  color: #222;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.44;
}
@media (max-width: 767px) {
  .equipment-detail__location {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .equipment-detail__location {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}
.equipment-detail__location .uk-icon {
  color: #edb509;
  margin-right: 10px;
}
.equipment-detail__btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 35px 0 60px;
}
@media only screen and (max-width: 639px) {
  .equipment-detail__btns {
    flex-direction: column;
    margin: 25px 0;
  }
}
.equipment-detail__btns a {
  width: calc(50% - 5px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  line-height: 67px;
  background-color: #fff9e7;
  color: #222;
  font-size: 18px;
  font-weight: 500;
  transition: 0.3s;
}
.equipment-detail__btns a:hover, .equipment-detail__btns a:focus, .equipment-detail__btns a:active {
  text-decoration: none;
  background-color: #edb509;
  color: #ffffff;
}
.equipment-detail__btns a:hover svg, .equipment-detail__btns a:focus svg, .equipment-detail__btns a:active svg {
  color: #ffffff;
}
.equipment-detail__btns a svg {
  color: #edb509;
  margin-right: 10px;
  transition: 0.3s;
}
@media only screen and (max-width: 639px) {
  .equipment-detail__btns a {
    width: 100%;
    margin-bottom: 10px;
  }
}
.equipment-detail__specification {
  margin-top: 60px;
}
@media only screen and (max-width: 639px) {
  .equipment-detail__specification {
    margin-top: 40px;
  }
}
.equipment-detail__specification table tr td:last-child {
  font-weight: 600;
}

.equipment-order {
  background-color: #f6f6f6;
}
.equipment-order + .equipment-order {
  margin-top: 35px;
}
.equipment-order__price {
  background-color: #222;
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  color: white;
  font-size: 40px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 767px) {
  .equipment-order__price {
    font-size: calc(30px + 17 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .equipment-order__price {
    font-size: calc(30px + 10 * (100vw / 1920));
  }
}
.equipment-order__price span {
  display: inline-block;
  position: relative;
}
.equipment-order__price span:before,
.equipment-order__price span:after {
  display: block;
  position: absolute;
  top: 15px;
  width: 30px;
  height: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
}
.equipment-order__price span::before {
  right: calc(100% + 10px);
  background-image: url(../img/decor/decor-title-left.svg);
}
.equipment-order__price span::after {
  left: calc(100% + 10px);
  background-image: url(../img/decor/decor-title-right.svg);
}
.equipment-order__price small {
  display: block;
  margin-top: 10px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .equipment-order__price small {
    font-size: calc(12px + 10.2 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .equipment-order__price small {
    font-size: calc(12px + 6 * (100vw / 1920));
  }
}
.equipment-order__form {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 20px 50px;
}
@media only screen and (max-width: 1200px) {
  .equipment-order__form {
    padding: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .equipment-order__form {
    padding: 20px 10px;
  }
}
@media only screen and (max-width: 959px) {
  .equipment-order__form {
    padding: 20px 50px;
  }
}
@media only screen and (max-width: 639px) {
  .equipment-order__form {
    padding: 20px;
  }
}
.equipment-order__form > * {
  flex: 1;
}
.equipment-order__form .uk-margin {
  min-width: 250px;
}
.equipment-order__form .datepicker-inline .datepicker {
  background: transparent;
  border: 0;
}
.equipment-order__form input {
  display: block;
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #dddddd;
  color: #555;
  font-size: 18px;
  font-weight: 500;
  outline: 0;
}
@media (max-width: 767px) {
  .equipment-order__form input {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .equipment-order__form input {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}
.equipment-order__value {
  color: #396;
  font-size: 18px;
  font-weight: 500;
}
.equipment-order__value .uk-icon {
  margin-right: 10px;
}

.equipment-order-total {
  background-color: #f6f6f6;
  margin-top: 35px;
  padding: 50px;
}
@media only screen and (max-width: 1200px) {
  .equipment-order-total {
    padding: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .equipment-order-total {
    padding: 20px 10px;
  }
}
@media only screen and (max-width: 959px) {
  .equipment-order-total {
    padding: 50px;
  }
}
@media only screen and (max-width: 639px) {
  .equipment-order-total {
    padding: 20px;
  }
}
.equipment-order-total ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 50px;
}
.equipment-order-total ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #555;
  font-size: 18px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .equipment-order-total ul li {
    font-size: calc(14px + 6.8 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .equipment-order-total ul li {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}
.equipment-order-total ul li + li {
  margin-top: 20px;
}
.equipment-order-total ul li span + span {
  color: #222222;
  font-weight: 600;
}
.equipment-order-total ul li:last-child {
  color: #222;
  font-size: 22px;
  font-weight: 700;
  padding-top: 25px;
  margin-top: 35px;
  border-top: 1px solid #dddddd;
}
@media (max-width: 767px) {
  .equipment-order-total ul li:last-child {
    font-size: calc(16px + 10.2 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .equipment-order-total ul li:last-child {
    font-size: calc(16px + 6 * (100vw / 1920));
  }
}

.js-data {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z'%3E%3C/path%3E%3Crect width='1' height='3' x='6' y='2'%3E%3C/rect%3E%3Crect width='1' height='3' x='13' y='2'%3E%3C/rect%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.datepicker--cell.-current- {
  color: #ffffff;
  background-color: #edb509;
}