.section-steps
  display: block
  position: relative
  padding-top: 110px
  padding-bottom: 100px
  +r(959)
    padding-top: 60px
    padding-bottom: 60px
  +r(639)
    padding-top: 40px
    padding-bottom: 40px

  .section-title
    margin-bottom: 62px
    .uk-h2
      margin-bottom: 8px

.steps-list
  position: relative
  outline: 0

  .uk-slider-items
    li:first-child 
      .steps-item
        &::before
          left: 105px
          width: calc(100% - 105px)
        &::after 
          content: ''
          display: block
          position: absolute
          top: 168px
          left: 95px
          width: 6px
          height: 6px
          border-radius: 3px
          background-color: #222222
    li:last-child 
      .steps-item
        &::before
          right: 85px
          width: calc(100% - 88px)
        &::after 
          content: ''
          display: block
          position: absolute
          top: 168px
          right: 78px
          width: 6px
          height: 6px
          border-radius: 3px
          background-color: #222222

.steps-item
  display: block
  position: relative
  padding-bottom: 100px
  cursor: pointer

  &::before
    content: ''
    display: block
    width: 100%
    height: 2px
    position: absolute
    left: 0
    top: 170px
    background-image: url(../img/decor/decor-steps-line.png)

  &__box
    max-width: 155px
    margin: 0 auto
    text-align: center
    position: relative

  &__icon
    display: inline-block
    position: relative
    margin-bottom: 149px
    &:after 
      content: ''
      display: block
      width: 15px
      height: 15px
      border-radius: 8px
      background-color: $accent
      position: absolute
      left: 50%
      margin-left: -7px
      bottom: -63px

  &__title
    display: inline-block
    position: relative
    b 
      display: inline-block
      position: absolute
      right: calc(100% - 20px) 
      bottom: -30px
      color: #f6f6f6
      font-size: 130px
      +fz(130, 110)
      font-weight: 700
      z-index: 1
      +r(959)
        right: calc(100% - 45px) 
      +r(639)
        right: calc(100% - 25px) 
    span 
      display: inline-block
      position: relative
      color: #222222
      font-size: 22px
      +fz(22, 18)
      line-height: 1.25
      font-weight: 600
      text-transform: uppercase
      z-index: 2
