.section-reviews 
  display: block
  position: relative
  padding: 110px 0

  +r(959)
    padding: 60px 0
  +r(767)
    padding: 40px 0

  .section-title
    margin-bottom: 55px

.review-item
  display: block
  position: relative
  padding-top: 10px
  &__box 
    display: block
    position: relative
    border-radius: 2px
    border: 1px solid #dddddd
    padding: 50px 70px 55px
    box-sizing: border-box
    background-color: #ffffff
    +r(1200)
      padding: 30px 40px
    +r(639)
      padding: 30px 20px
    &::before
      content: ''
      display: block
      width: 27px
      height: 19px
      background-image: url(../img/icons/ico-quote.png)
      background-position: center 
      background-repeat: no-repeat
      position: absolute
      top:  -10px
      left: 70px
      background-color: #ffffff
      padding: 0 10px
      +r(767)
        left: 30px
  &__title
    color: #222222
    font-size: 18px
    font-weight: 500
    line-height: 1.44
    margin-bottom: 13px
  &__text
    color: #555555
    font-weight: 400
    line-height: 1.67
  &__user
    margin-top: 30px
    display: flex
    justify-content: flex-start
    align-items: center
  &__avatar
    width: 50px
    height: 50px
    border-radius: 50%
    overflow: hidden
    margin-right: 13px
    img 
      width: 100%
      height: 100%
      object-fit: cover 
      object-position: center
  &__info
    margin-right: 14px
  &__name
    color: #222222
    font-size: 18px
    +fz(18, 14)
    line-height: 1.3
    font-weight: 500
  &__position
    color: #888888
    font-size: 14px
    +fz(14, 12)
    line-height: 1.3
    font-weight: 400
  &__stars
    margin-top: -15px