.contacts-block
  display: block
  position: relative
  .sidebar 
    height: 100%
    box-sizing: border-box
    padding: 40px
    +r(639)
      padding: 20px
  .block-form
    height: 100%
    box-sizing: border-box
    +r(959)
      margin-top: 30px
    +r(639)
      margin-top: 15px

  .contacts-list 
    .contacts-list-item
      align-items: flex-start
      & + .contacts-list-item
        margin-top: 40px
        padding-top: 40px
        border-color: #ffffff
        +r(639)
          margin-top: 20px
          padding-top: 20px
      &__icon
        color: #ffffff
        .cls-1
          stroke: #ffffff
      &__desc
        padding-left: 20px
      &__label
        color: #ffffff
      &__content
        a
          +hh 
            color: $dark

.contacts-map
  display: block
  position: relative
  margin-top: 70px
  +r(959)
    margin-top: 60px
  +r(639)
    margin-top: 30px
  iframe
    width: 100%
    height: 500px
    +r(639)
      height: 300px