// Custome style

body
  min-width: 320px

.page-wrapper
  min-height: 100vh
  display: flex
  flex-direction: column
  justify-content: space-between

  .page-main
    flex-grow: 1

.social
  display: block
  position: relative

.social-list
  display: flex
  justify-content: flex-start
  align-items: center
  padding: 0
  margin: 0
  &__item
    display: block
    position: relative
    & + .social-list__item
      margin-left: 20px
  &__link
    display: block
    position: relative
    font-size: 16px
    color: #666
    +tr(0.5s)
    +hh
      color: #ffffff

.uk-container
  max-width: 1140px

.uk-container-large
  max-width: 1270px

.uk-container-xlarge
  max-width: 1500px

@media (min-width: 960px)
  .uk-section-large
    padding-top: 120px
    padding-bottom: 120px

.uk-overlay-primary
  background-color: rgba(#000000, 0.8)

.uk-nav-parent-icon>.uk-parent>a:after
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' data-svg='chevron-down'%3E%3Cpolyline fill='none' stroke='%23fff' stroke-width='1.03' points='16 7 10 13 4 7'%3E%3C/polyline%3E%3C/svg%3E")

.uk-nav-parent-icon>.uk-parent.uk-open>a:after
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' data-svg='chevron-up'%3E%3Cpolyline fill='none' stroke='%23fff' stroke-width='1.03' points='4 13 10 7 16 13'%3E%3C/polyline%3E%3C/svg%3E")

.uk-button
  display: inline-flex
  justify-content: center
  align-items: center
  border-radius: 2px
  background-color: #edb509
  color: #ffffff
  font-size: 18px
  +fz(18, 14)
  font-weight: 700
  text-transform: uppercase
  white-space: nowrap
  +tr(0.5s)
  img, svg
    display: block
    width: 24px
    height: 15px
    flex: 0 0 24px
    margin-left: 14px
  +hh
    color: #ffffff
    background-color: lighten($accent, 5%)

.uk-button-default
  background-color: transparent
  border: 1px solid #dddddd
  color: #222222
  svg
    color: $accent
    +tr(0.5s)
  +hh
    border-color: $dark
    background-color: $dark
    color: #ffffff
    +tr(0.5s)
    svg
      color: #ffffff
      +tr(0.5s)

.uk-button-secondary
  background-color: $dark

.uk-button-danger
  border-radius: 2px
  background-color: #f26e21

.uk-button-large
  line-height: 62px

.uk-input,
.uk-select,
.uk-textarea
  color: #222222
  font-size: 16px
  font-weight: 600
  +placeholder()
    color: #222222
    font-size: 16px
    font-weight: 600

.uk-textarea
  height: 130px

.uk-form-large:not(textarea):not([multiple]):not([size])
  height: 62px

.uk-select:not([multiple]):not([size])
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A)
  background-repeat: no-repeat
  background-position: 98% 50%

.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus
  outline: none
  background-color: #fff
  color: #222222
  border-color: $grey

.section-header
  display: flex
  justify-content: space-between
  align-items: flex-start
  +r(767)
    flex-direction: column

  .uk-button
    +r(767)
      display: none

.title
  &:after
    content: ''
    display: block
    position: relative
    width: 30px
    height: 8px
    background-image: url(../img/decor/decor-title.svg)
    margin-top: 11px

.uk-tooltip
  max-width: 280px
  padding: 20px
  border-radius: 0
  box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06)
  background: #ffffff
  color: #222222
  font-family: Barlow
  font-size: 14px
  font-weight: 400
  font-style: normal
  letter-spacing: normal
  line-height: 1.2
  text-align: center
  &::before
    content: ''
    display: block
    width: 0
    height: 0
    border-left: 25px solid transparent
    border-right: 25px solid transparent
    border-bottom: 15px solid #ffffff
    position: absolute
    top: -10px
    left: 50%
    transform: translateX(-50%)

.uk-dotnav
  align-items: center
  >*>*
      width: 8px
      height: 8px
      border-radius: 4px
      background-color: #dddddd
      border: 0
  >.uk-active>*
    width: 12px
    height: 12px
    border-radius: 6px
    background-color: #222222

.uk-pagination
  li
    & + li
      margin-left: 10px

  >*>*
    display: flex
    justify-content: center
    align-items: center
    width: 55px
    height: 55px
    border-radius: 2px
    border: 1px solid #dddddd
    padding: 0
    color: #222222
    font-size: 18px
    +fz(18, 14)
    font-weight: 700
    text-transform: uppercase
    +tr(0.5s)
    +r(639)
      width: 40px
      height: 40px

  >.uk-active>*
    border-color: $accent
    background-color: $accent
    color: #ffffff

  >*>:focus,
  >*>:hover
    border-color: $accent
    background-color: $accent
    color: #ffffff
    +tr(0.5s)

  .uk-pagination-next
    border: 0
    img, svg
      width: 27px
      height: 15px
    +tr(0.3s)
    +hh
      background: none
      color: $accent

.uk-lightbox
  background-color: rgba(#000000, 0.8)

.header-icons
  display: flex
  justify-content: center
  align-items: center
  position: relative
  margin-bottom: 45px
  &::after
    content: ''
    display: block
    position: absolute
    bottom: -25px
    left: 50%
    margin-left: -32px
    width: 64px
    height: 8px
    background-image: url(../img/decor/decor-title-2.svg)
  > span
    width: 55px
    height: 55px
    background-size: contain!important
    background-position: center!important
    background-repeat: no-repeat!important

    &:nth-child(1)
      background-image: url(../img/icons/ico-slideshow-1.svg)
    &:nth-child(2)
      background-image: url(../img/icons/ico-slideshow-2.svg)
    &:nth-child(3)
      background-image: url(../img/icons/ico-slideshow-3.svg)

    & + span
      margin-left: 27px

.page-head
  display: block
  position: relative
  &:after
    display: block
    width: 100%
    max-width: 500px
    height: 25px
    position: absolute
    bottom: -12px
    left: 50%
    transform: translateX(-50%)
    z-index: 2
    background-image: url(../img/decor/decor-lines.svg)
    content: ''
  &__bg
    display: block
    position: relative
    background-position: center
    background-size: cover
    padding: 100px 0 120px
    +r(639)
      padding: 80px 0
    &::before
      content: ''
      display: block
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-color: rgba(#000000, 0.6)
      z-index: 1
  &__content
    display: block
    position: relative
    z-index: 2
  &__title
    text-align: center
    color: #ffffff
    font-size: 64px
    +fz(64, 32)
    font-weight: 400
    line-height: 70px
    text-align: center
    margin: 45px 0 35px
  &__breadcrumb
    text-align: center
    .uk-breadcrumb
      >*>*
        display: inline-block
        position: relative
        color: #ffffff
        font-size: 14px
        font-weight: 600
        text-align: center
        text-transform: uppercase
        padding: 0 15px
        &::before
          content: ''
          display: block
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%
          -webkit-transform: skew(-30deg)
          -moz-transform: skew(-30deg)
          -o-transform: skew(-30deg)
          background: $accent
          z-index: -1
      &>:nth-child(n+2):not(.uk-first-column):before
        display: none
      &>:last-child>a:not([href]),
      &>:last-child>span
        color: #ffffff
        font-size: 14px
        font-weight: 700
        text-transform: uppercase
        &::before
          display: none

  &__form
    display: block
    position: relative
    margin-top: 80px
    padding-top: 80px
    +r(959)
      margin-top: 60px
      padding-top: 60px
    +r(639)
      margin-top: 30px
      padding-top: 30px
    &:before
      -webkit-transform: translateX(-50%)
      -ms-transform: translateX(-50%)
      display: block
      position: absolute
      top: 0
      left: 50%
      width: 100%
      height: 13px
      transform: translateX(-50%)
      background-image: url(../img/decor/decor-lines-grey.png)
      content: ''

.list-checked
  margin: 0
  padding: 0
  list-style-type: none
  li
    display: flex
    justify-content: flex-start
    align-items: center
    position: relative
    + li
      margin-top: 5px
    &::before
      content: ''
      display: block
      width: 17px
      height: 17px
      background-image: url(../img/icons/ico-check.png)
      margin-right: 14px

.tags-list
  margin: 0
  padding: 0
  list-style-type: none
  display: flex
  justify-content: flex-start
  align-items: center
  flex-wrap: wrap
  li
    margin-right: 5px
    margin-bottom: 5px
    a
      display: inline-block
      color: #222222
      font-size: 14px
      font-weight: 600
      text-transform: uppercase
      white-space: nowrap
      height: 40px
      line-height: 40px
      padding: 0 20px
      border: 2px solid #dddddd
      background-color: #ffffff
      box-sizing: border-box
      +tr(0.3s)
      +hh
        background-color: $accent
        color: #ffffff
        text-decoration: none

.share-list
  display: flex
  justify-content: flex-start
  align-items: center
  padding: 0
  margin: 0
  li
    display: block
    position: relative
    & + li
      margin-left: 8px
    a
      display: block
      position: relative
      width: 30px
      height: 30px
      font-size: 16px
      color: #666
      +tr(0.5s)
      +hh
        color: #ffffff

.block-form
  box-shadow: 0 5px 24px 6px rgba(0, 0, 0, 0.06)
  background-color: #ffffff
  padding: 63px 70px
  +r(1200)
    padding: 30px
  +r(639)
    padding: 15px

  .section-content
    p
      color: #555555
      font-size: 16px
      font-style: italic
      letter-spacing: normal
      line-height: 26px
      margin-bottom: 40px

  .section-title
    margin-top: 0
    margin-bottom: 15px
    .uk-h2
      color: #222222
      font-size: 30px
      font-weight: 700
      line-height: 1.2
      margin: 0

  .uk-button
    +r(639)
      width: 100%