.page-header
  display: block
  position: relative

.page-header-top
  display: flex
  justify-content: space-between
  align-items: center
  padding: 18px 80px
  padding: 18px 4.17vw
  background-color: $dark
  font-size: 16px
  +fz(16, 13)
  font-weight: 400
  color: #ffffff
  +r(1200)
    display: block

  +r(1440)
    padding: 10px 15px

  &__left 
    display: block
    position: relative
    +r(1200)
      display: none

  &__right 
    display: flex
    justify-content: flex-end
    align-items: center

    +r(1200)
      justify-content: space-between

    & > * + *
      margin-left: 2.08vw
      padding-left: 2.08vw
      border-left: 1px solid #555555
      +r(1200)
        margin: 0
        padding: 0
        border: 0

    .social
      +r(639)
        display: none

.page-header-bottom 
  display: flex
  justify-content: space-between
  align-items: center
  background-color: #ffffff
  border-bottom: 1px solid #dddddd
  box-sizing: border-box

  &.uk-active
    box-shadow: 0 0 15px 5px rgba(#000000, 0.15)

  &__left
    display: flex
    justify-content: flex-start
    align-items: center

    .logo
      padding: 0 5.21vw 0 4.17vw

      +r(1440)
        padding: 0 2.08vw 0 15px
    
    .support 
      padding: 20.5px 2.08vw
      border-left: 1px solid #dddddd
      border-right: 1px solid #dddddd

      +r(1440)
        padding-top: 11px
        padding-bottom: 12px
      +r(959)
        padding: 0
        display: flex
        justify-content: center 
        align-items: center
        width: 60px
        height: 60px
      +r(424)
        display: none

      &__icon
        .svg-inline--fa,
        .fas
          +r(959)
            margin-right: 0
            font-size: 30px
      &__desc
        +r(959)
          display: none

  &__right
    flex: 1
    display: flex
    justify-content: flex-end
    max-width: 50%
    +r(1200)
      max-width: none

    .uk-navbar
      flex: 1
      justify-content: flex-end

    .uk-navbar-nav
      margin-right: 60px
      margin-right: 3.13vw
      +r(1440)
        margin-right: 20px

    .uk-navbar-item, 
    .uk-navbar-nav>li>a, 
    .uk-navbar-toggle
      font-family: "Barlow"
      min-height: 100px
      +r(1440)
        min-height: 80px
      +r(959)
        min-height: 60px

    .uk-navbar-nav
      >li
        >a
          color: #222222
          font-size: 18px
          +fz(18, 12)
          font-weight: 500
          text-transform: uppercase
          white-space: nowrap
          padding: 0 15px
          padding: 0 0.781vw
          svg, i 
            margin-left: 11px
            color: #666666
        &.uk-active
          a 
            color: $accent
    
    [class*=uk-navbar-dropdown-bottom]
      margin-top: 3px

    .uk-navbar-toggle
      width: 100px
      flex: 0 0 100px
      border-left: 1px solid #dddddd

      +r(1440)
        width: 80px
        flex: 0 0 80px

      +r(959)
        width: 60px
        flex: 0 0 60px

      svg 
        color: #222222
        width: 24px
        height: 24px

    .uk-search-navbar 
      .uk-search-input
        font-size: 24px
        +fz(24, 16)

    .search-btn
      +r(639)
        display: none
    
    .cart-btn
      border: 0
      &__icon
        svg
          color: #ffffff
          width: 40px
          height: 40px
      

.update
  svg,
  .far 
    margin-right: 10px
    color: $accent
  a 
    color: white
    font-size: 16px
    +fz(16,13)
    font-weight: 600
    color: #ffffff
    text-decoration: underline
    +tr(0.3s)
    +hh 
      color: $accent
      text-decoration: none


.time-work 
  display: flex
  align-items: center
  position: relative
  color: white
  font-size: 16px
  +fz(16,13)
  font-weight: 400
  svg,
  .far 
    margin-right: 10px
    color: $accent

.login-link
  a 
    color: white
    font-size: 16px
    +fz(16,13)
    font-weight: 400
    color: #ffffff
    +tr(0.3s)
    +hh 
      color: $accent
      text-decoration: none
  svg,
  .far 
    margin-right: 10px
    color: $accent

.logo
  display: block
  position: relative
  &__link
    display: block
    position: relative
  &__img 
    +r(959)
      max-height: 40px

.support 
  &__link
    display: flex
    justify-content: flex-start
    align-items: center
    text-decoration: none
    +tr(0.3s)
    +hh 
      text-decoration: none
      .support__phone
        text-decoration: none
        color: $accent
  &__icon
    .svg-inline--fa,
    .fas
      font-size: 36px
      margin-right: 20px
      color: $accent
  &__label
    color: #222
    font-size: 16px
    font-weight: 500
  &__phone
    color: #222
    font-size: 28px
    +fz(28, 20)
    line-height: 1
    font-weight: 600
    +tr(0.3s)

.cart-btn
  background-color: $accent
  color: #ffffff
  &__icon
    display: block
    position: relative
    svg
      color: #ffffff
  &__count 
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    top: -2px
    right: -10px
    width: 19px
    height: 19px
    border-radius: 10px
    background-color: #ffffff

    color: #222222
    font-size: 12px
    font-weight: 500
    text-transform: uppercase